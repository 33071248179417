import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";   //  Navigate,
import axios from 'axios';
import { UserContext } from "../UserContext";
import { LoginPathContext } from "../LoginPathContext";
// useContext returns the context value for the context you passed. To determine the context value, React searches the component tree and finds the closest 
// context provider above for that particular context. 
// It doesn't matter how many layers of components there are between the provider and the Button .
// The <Outlet> renders the current route selected
// <Link> is used to set the URL and keep track of browsing history. Anytime we link to an internal path, we will use <Link> instead of <a href="">
//import { Outlet, Link } from "react-router-dom";


function LoginPage({ path }) {

    const [username, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [err, setError] = useState('');
    const [redirect, setRedirect] = useState(false);
    const navigate = useNavigate();
    const { setCurrentUser } = useContext(UserContext);   // setCurrentUser grabed from UserContext with the help of useContext, It works just like prop
    const { contextValue } = useContext(LoginPathContext); // Access the loginPath state from the context
    const [loginButtonLoaderHidden, setLoginButtonLoaderHidden] = useState(true);

    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setLoginButtonLoaderHidden(false);
            // grab information about the /login information from app.js
            const userInfo = await axios.post('/login', {
                username,
                password
            });
          
            // Assuming your server sends an error message if login fails
            if (userInfo.data.error) {
                console.error(err);
                setLoginButtonLoaderHidden(true)
                setError(userInfo.data.error);
                navigate('/login');
            } else {
                setCurrentUser(userInfo.data)
                setLoginButtonLoaderHidden(true);
                setRedirect(true);
                alert("Login Successful");
                if (contextValue.length > 1 && contextValue[contextValue.length - 1] !== '/login') {
                    navigate(contextValue[contextValue.length - 1]);
                }
                else if (contextValue.length > 1 && contextValue[contextValue.length - 1] === '/login') {
                    navigate(contextValue[contextValue.length - 2]);
                }
                else if (contextValue[0] === '/login') {
                    navigate('/');
                }
                else {
                    navigate('/');
                }

            }
        } catch (err) {
            console.error("Error:", err);
            setLoginButtonLoaderHidden(true);
            setTimeout(() => {
                alert("Login failed, try again!");
            }, 50);
            navigate('/login');
        }
        if (redirect) {
            return
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };


    return (
        <div className="mt-4 md:mt-20 grow flex items-center justify-around flex-col min-h-screen" >
            <div className=" flex-grow justify-center">
                <h1 className="text-4xl text-center mb-6">Login</h1>
                <h2 className="text-2xl text-center m-2">Welcome to Nookily</h2>
                <form className="max-w-md mx-auto " autoComplete="on" onSubmit={handleSubmit}>
                    <input type="email" className="h-14" placeholder="your-email@email.com" autoComplete="off"
                        value={username} required onChange={(e) => { setEmail(e.target.value) }} />
                    <input type="password" className="h-14" placeholder="password" autoComplete="false"
                        value={password} required onChange={(e) => { setPassword(e.target.value) }} />
                    <button className={loginButtonLoaderHidden ? (" primary mt-1 h-12 shadow-md hover:bg-rose-600 !rounded-md ")
                        : ("!bg-rose-400 mt-1 h-12 w-full !rounded-md ")}
                        disabled={!loginButtonLoaderHidden} >
                        {loginButtonLoaderHidden ? (
                            <p className="text-white">Login</p>
                        ) : (
                            <div className="lds-elliipsis" disabled >
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        )}
                    </button>

                    {/* <Link to={"/auth/twitter"} className="py-2 mt-2 px-4 flex text-decoration-none h-12  justify-center items-center mb-2 bg-blue-600 hover:bg-blue-700
                     focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md ">
                        <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">

                        </svg>
                        Sign in with Twitter
                    </Link>

                    <Link to={"/auth/google"} className="py-2 px-4 flex text-decoration-none justify-center items-center mb-2 bg-red-600 hover:bg-red-700 focus:ring-red-500
                     focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 h-12 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md ">
                        <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">

                        </svg>
                        Sign in with Google
                    </Link> */}
                    <div className="text-center  text-gray-500">
                        <span>  Don't have an account yet? </span>
                        <Link className="underline text-black" to={'/register'} onClick={scrollToTop} >Register now </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default LoginPage;