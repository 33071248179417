import { Link } from 'react-router-dom';

function CookiesFooter() {
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
      };

    return (
             
                
                    <div className="sm:flex sm:justify-between mb-2">
                        <p className="text-xs text-gray-500">
                            &copy; {new Date().getFullYear()}. Nookily. All rights reserved.
                        </p>

                        <ul className="mt-8 flex flex-wrap justify-start gap-4 text-xs sm:mt-0 lg:justify-end" >
                            <li>
                                <Link to={"#"} className="text-gray-500 transition hover:opacity-75" onClick={scrollToTop}>
                                    Terms & Conditions
                                </Link>
                            </li>

                            <li>
                                <Link to={"#"} className="text-gray-500 transition hover:opacity-75" onClick={scrollToTop}>
                                    Privacy Policy
                                </Link>
                            </li>

                            <li>
                                <Link to={"#"} className="text-gray-500 transition hover:opacity-75" onClick={scrollToTop}>
                                    Cookies
                                </Link>
                            </li>
                        </ul>
                    </div>
               
          

    )
}
export default CookiesFooter;