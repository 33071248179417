// context provider above for that particular context. 
// It doesn't matter how many layers of components there are between the provider and the Button .
import React, { useContext } from "react";
//import axios from 'axios'; // Axios is used to communicate with the backend and it also supports the Promise API that is native to JS ES6. It is a library which is used to make requests to an API, return data from the API, and then do things with that data in our React application.
import { Link, useNavigate, useParams } from "react-router-dom";   //  Navigate, useParams You can use it to retrieve route parameters from the component rendered by the matching route
import { UserContext } from "../UserContext.jsx";  // useContext returns the context value for the context you passed. To determine the context value, React searches the component tree and finds the closest 
//import { LoginPathContext } from "../LoginPathContext";
import PlacesPage from './PlacesPage.jsx';
import MyBookingPage from "./MyBookingPage.jsx";
import MyProfilePage from "./MyProfilePage.jsx";
import MyFavouritesPage from "./MyFavouritesPage.jsx";



function AccountPage() {
  // The useContext hook is used to access the user context (UserContext) to retrieve ready, currentUser, and setCurrentUser. These are used to determine if the user is logged in and to manage user data.
  const { ready, currentUser } = useContext(UserContext); // return ready, currentUser and setCurrentUser
  //const { contextValue } = useContext(LoginPathContext); // Access the loginPath state from the context
  const navigate = useNavigate();

  // const { pathname } = useLocation() // to determina the location of a path

  // useParams rule : If you early return somethings before the hook is invoked, it breaks the rules of hook.
  let { subpage } = useParams();
  if (subpage === undefined) { //The useParams hook is used to retrieve the subpage route parameter. If subpage is not defined, it defaults to 'profile'. This parameter is used to determine which subpage of the account page to display.
    subpage = 'profile';
  }
  if (ready && !currentUser) { // This code checks if the user is logged in (currentUser is defined) and if the context data is ready. If not, it navigates the user to the login page.
    return navigate("/");
  }

  // Add classes only if link is active
  function linkClasses(type = null) { // This function is used to generate CSS classes for the navigation links. The classes are based on the type provided, and they change if the current subpage matches the type.
    let classes = 'inline-flex gap-1.5 py-2 px-6 !rounded-xl flex justify-center text-center items-center  shadow-md '
    if (type === subpage) {
      classes += " bg-primary  shadow-red-500/20 ";
      //console.log(pathname);
    }
    else {
      classes += " bg-gray-100";
    }
    return classes;
  }





  return (
    <div className="grow flex flex-col min-h-screen" >
      <div className="lg:grid-cols-4 ">  {/* The component renders a user account page that includes navigation links, user information, and subpages based on the subpage parameter. */}
        <nav className="w-full grid grid-cols-3 gap-3 mt-8 justify-center mb-4 ">  {/* Navigation links are provided for the user to navigate to different sections of their account: "My profile," "My bookings," and "My accommodations." */}
          <Link to={'/account/profile'} className={linkClasses('profile')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 6a3.75 3.75 0 11-7.5 0 3.75 3.75 0 017.5 0zM4.501 20.118a7.5 7.5 0 0114.998 0A17.933 17.933 0 0112 21.75c-2.676 0-5.216-.584-7.499-1.632z" />
            </svg> <span className=" hidden sm:flex ">My&nbsp;profile </span>
          </Link>
          <Link to={'/account/bookings'} className={linkClasses('bookings')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 6.75h12M8.25 12h12m-12 5.25h12M3.75 6.75h.007v.008H3.75V6.75zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zM3.75 12h.007v.008H3.75V12zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0zm-.375 5.25h.007v.008H3.75v-.008zm.375 0a.375.375 0 11-.75 0 .375.375 0 01.75 0z" />
            </svg> <span className=" hidden sm:flex ">My&nbsp;bookings</span>
          </Link>
          <Link to={'/account/places'} className={linkClasses('places')}>
            <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
              <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 21v-4.875c0-.621.504-1.125 1.125-1.125h2.25c.621 0 1.125.504 1.125 1.125V21m0 0h4.5V3.545M12.75 21h7.5V10.75M2.25 21h1.5m18 0h-18M2.25 9l4.5-1.636M18.75 3l-1.5.545m0 6.205l3 1m1.5.5l-1.5-.5M6.75 7.364V3h-3v18m3-13.636l10.5-3.819" />
            </svg> <span className=" hidden sm:flex ">My&nbsp;places</span>
          </Link>
        </nav>
        {/* If the subpage is 'profile' and there's a currentUser, the user's name and username are displayed, along with a "Logout" button. */}
        {subpage === 'profile' && currentUser && (
          <MyProfilePage />
        )}
        {/* If the subpage is 'places', the PlacesPage component is rendered. This component appears to be related to user accommodations. */}
        {subpage === 'places' && (
          <PlacesPage />
        )}
        {subpage === "bookings" && (
          <MyBookingPage />
        )}
        {subpage === "favourites" && (
          <MyFavouritesPage />
        )}
      </div>
    </div>
  );
}

export default AccountPage;

