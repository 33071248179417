import React from "react";
import { Link } from "react-router-dom";

function Trademarks() {
   
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className=" inset-0 bg-white min-h-screen z-50 absolute w-full  -top-20 " >
            <div className=" m-3 p-2">
                <h1 className=" font-bold text-2xl text-primary">
                    <Link to={"/"} className=" transition hover:opacity-75" onClick={scrollToTop}>Nookily</Link>
                </h1>
            </div>
            <div className=" p-12 sm:p-16 md:p-28  bg-primary">
                <h1 className=" text-white font-bold text-xl text-left md:text-6xl">Trademark Notice</h1>
            </div>
            <div className="p-12  md:px-28 md:py-10 ">
                <p className="py-2 text-lg">Trademark Notice</p>
                <ol class=" pl-4 gap">
                    <li className="font-medium py-2">Trademark Ownership & Notice</li>
                    <ul className="list-disc" >
                        <li>The Nookily name, logo, and any other trademarks displayed on the Nookily website are registered or unregistered trademarks of Nookily Technology Limited. All rights are reserved.</li>
                    </ul>
                    <li className="font-medium py-2">Usage Restrictions</li>
                    <ul className="list-disc" >
                        <li>Visitors to the Nookily website are granted a limited, non-exclusive, and non-transferable license to access and use the content and materials solely for personal and non-commercial purposes. Any unauthorized use, reproduction, distribution, or modification of the content without prior written consent from Nookily is strictly prohibited.</li>
                    </ul>
                    <li className="font-medium py-2">Prohibited Uses</li>
                    <ul className="list-disc" >
                        <li>Prohibited uses of the Nookily trademarks include, but are not limited to, using the trademarks as part of a domain name, social media handle, or company name; modifying or altering the trademarks; and using the trademarks in a manner that is disparaging or defamatory.</li>
                    </ul>
                    <li className="font-medium py-2">Attribution Requirement</li>
                    <ul className="list-disc" >
                        <li>When using the Nookily trademarks, proper attribution must be provided, indicating that the trademarks are owned by Nookily.</li>
                    </ul>
                    <li className="font-medium py-2">Enforcement of Rights</li>
                    <ul className="list-disc" >
                        <li>Nookily reserves the right to take appropriate legal action to enforce its rights in the Nookily trademarks, including seeking injunctive relief, damages, and attorney's fees.</li>
                    </ul>
                    <li className="font-medium py-2">Third-Party Trademarks</li>
                    <ul className="list-disc" >
                        <li>All other trademarks, service marks, and logos displayed on the Nookily website are the property of their respective owners. Any use of these trademarks without permission from the respective owners is strictly prohibited.</li>
                    </ul>
                    <li className="font-medium py-2">Governing Law</li>
                    <ul className="list-disc" >
                        <li>This copyright notice shall be governed by and construed in accordance with the laws, without regard to its conflict of law provisions.</li>
                    </ul>
                </ol>

                <p className=" py-2">All content, including text, images, graphics, and other materials on the Nookily website are subject to copyright and other intellectual property rights under applicable laws, unless otherwise stated. These materials may not be reproduced, distributed, modified, or reposted to other websites without the express written permission of Nookily.</p>
                <p className=" py-2 ">Nookily and its logo are trademarks or registered trademarks of Nookily. Any unauthorized use of these trademarks is strictly prohibited. </p>
                <div className=" pb-8">
                    <p className="  py-2 ">For inquiries regarding the use of Nookily's copyrighted materials or trademarks, please contact us at <Link to="mailto:contact@nookily,com" className=" underline text-blue-500 transition hover:opacity-75"> contact@nookily.com</Link> </p>
                </div>

            </div>
        </div>
    )
}
export default Trademarks;