import React from "react";
// The <Outlet> renders the current route selected
// <Link> is used to set the URL and keep track of browsing history. Anytime we link to an internal path, we will use <Link> instead of <a href="">
import { Outlet, Link } from "react-router-dom";


function NoPage() {
    return (
        <div className="  grow flex flex-col min-h-screen items-center justify-center 
         lg:flex-row md:gap-28 gap-16 bg-cover bg-center        
        bg-blend-overlay bg-[url('https://i.ibb.co/ck1SGFJ/Group.png')] ">
            <div className="flex-grow justify-center flex items-center">
                <div className="text-center">
                    <h1 className="my-2 text-gray-700 font-bold text-xl">
                        Looks like you've found the doorway to the great nothing
                    </h1>
                    <p className="my-2">
                        Sorry about that! Please visit our homepage to get where you need
                        to go.
                    </p>
                    <Link to="/" className="flex justify-center">
                        <button className="w-36 my-2 border p-2 text-center bg-primary text-white rounded-full">
                            Go Home
                        </button>
                    </Link>
                </div>
            </div>
            <Outlet />
        </div>
    )
}
export default NoPage;
