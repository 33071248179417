import React from "react";
import { Link } from "react-router-dom";

function Copyright() {
    const copyrightDate = () => {
        return new Date().getFullYear()
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className=" inset-0 bg-white min-h-screen z-50 absolute w-full  -top-20 " >
            <div className=" m-3 p-2">
                <h1 className=" font-bold text-2xl text-primary">
                    <Link to={"/"} className=" transition hover:opacity-75" onClick={scrollToTop}>Nookily</Link>
                </h1>
            </div>
            <div className=" p-12 sm:p-16 md:p-28  bg-primary">
                <h1 className=" text-white font-bold text-xl text-left md:text-6xl">Copyright Notice</h1>
            </div>
            <div className="p-12  md:px-28 md:py-10 ">
                <p className="py-2">Copyright © {copyrightDate()} Nookily. All rights reserved</p>
                <ol class=" pl-4 gap">
                    <li className="font-medium py-2">Copyright Ownership</li>
                    <ul className="list-disc" >
                        <li>All content and materials on the Nookily website, including but not limited to text, images, graphics, logos, videos, software, and designs, are the intellectual property of Nookily unless otherwise indicated.</li>
                    </ul>
                    <li className="font-medium py-2">Usage Restrictions</li>
                    <ul className="list-disc" >
                        <li>Visitors to the Nookily website are granted a limited, non-exclusive, and non-transferable license to access and use the content and materials solely for personal and non-commercial purposes. Any unauthorized use, reproduction, distribution, or modification of the content without prior written consent from Nookily is strictly prohibited.</li>
                    </ul>
                    <li className="font-medium py-2">Trademark Notice</li>
                    <ul className="list-disc" >
                        <li>The Nookily name, logo, and any other trademarks displayed on the website are the registered or unregistered trademarks of Nookily. Any use of these trademarks without prior written permission is strictly prohibited and may violate trademark laws.</li>
                    </ul>
                    <li className="font-medium py-2">User-Generated Content</li>
                    <ul className="list-disc" >
                        <li>Nookily may feature user-generated content, including but not limited to reviews, comments, and submissions. By submitting content to Nookily, users grant Nookily a perpetual, irrevocable, royalty-free, and worldwide license to use, modify, reproduce, distribute, and display the content in any form and on any media.</li>
                    </ul>
                    <li className="font-medium py-2">Third-Party Content</li>
                    <ul className="list-disc" >
                        <li>Nookily may contain links to third-party websites, services, or resources that are not owned or controlled by Nookily. Nookily does not endorse or assume any responsibility for the content, privacy policies, or practices of these third parties.</li>
                    </ul>
                    <li className="font-medium py-2">Disclaimer of Warranties</li>
                    <ul className="list-disc" >
                        <li>Nookily makes no representations or warranties of any kind, express or implied, regarding the accuracy, completeness, reliability, or suitability of the content and materials on the website. The use of the website is at the user's own risk.</li>
                    </ul>
                    <li className="font-medium py-2">Limitation of Liability</li>
                    <ul className="list-disc" >
                        <li>In no event shall Nookily be liable for any direct, indirect, incidental, special, or consequential damages arising out of or in connection with the use or inability to use the website or its content, even if Nookily has been advised of the possibility of such damages.</li>
                    </ul>
                    <li className="font-medium py-2">Governing Law</li>
                    <ul className="list-disc" >
                        <li>This copyright notice shall be governed by and construed in accordance with the laws, without regard to its conflict of law provisions.</li>
                    </ul>
                </ol>


                <p className=" py-2">All content, including text, images, graphics, and other materials on the Nookily website are subject to copyright and other intellectual property rights under applicable laws, unless otherwise stated. These materials may not be reproduced, distributed, modified, or reposted to other websites without the express written permission of Nookily.</p>
                <p className=" py-2 ">Nookily and its logo are trademarks or registered trademarks of Nookily. Any unauthorized use of these trademarks is strictly prohibited. </p>
                <div className=" pb-8">
                    <p className="  py-2 ">For inquiries regarding the use of Nookily's copyrighted materials or trademarks, please contact us at <Link to="mailto:contact@nookily,com" className=" underline text-blue-500 transition hover:opacity-75"> contact@nookily.com</Link> </p>
                </div>

            </div>
        </div>
    )
}
export default Copyright;