import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";
import { UserContext } from "../UserContext";
import { BsChevronCompactLeft, BsChevronCompactRight } from 'react-icons/bs';
import { RxDotFilled } from 'react-icons/rx';
import axios from "axios";

function MarketPlace() {
    const apiUrl = axios.defaults.baseURL;
    axios.defaults.withCredentials = true;
    const openWeatherUrl = 'https://api.openweathermap.org/img/w/';
    const { currentUser } = useContext(UserContext);
    const [homePlaces, setHomePlaces] = useState([]);
    const [isApiBlocked, setApiBlocked] = useState(false)
    const [favourite, setFavourite] = useState([]);
    const [weatherData, setWeatherData] = useState([]);
    const [currentImgIndices, setCurrentImgIndices] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            const { data } = await axios.get('/marketplace');
            setHomePlaces(data.foundId);
            const weatherMessage = data.weatherDataInfo;
            // console.log(weatherMessage);
            let isBlocked = false;
            Object.keys(weatherMessage).forEach(city => {
                // check if weather API is blocked
                const blockedCodes = [401, 404, 500, 502, 503, 504];
                if (blockedCodes.includes(weatherMessage[city].cod)){
                    isBlocked = true;             
                }
            });
            if (isBlocked) {
                setApiBlocked(true);
            }
            else{
                setApiBlocked(false);
            }
            setWeatherData(data.weatherDataInfo);

            // Initialize current image indices for each homePlace
            // iterates over the fetched homePlaces and assigns 0 to each homePlace's initial image index.
            const initialIndices = {};
            data.foundId.forEach((_, index) => {
                initialIndices[index] = 0;
            });
            setCurrentImgIndices(initialIndices);
            if (currentUser) {
                const { data } = await axios.get('/profile/favourite');
                // map the data received from the /profile/favourite endpoint to an array of placeID.
                const favouritePlaceIds = data.map(item => item.placeID);
                setFavourite(favouritePlaceIds);
            }
        }
        fetchData()
            .catch(error => {
                console.error("Error fetching places:", error);
            });
    }, [currentUser]);
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" });
    }

    async function handleLike(placeID) {
        if (!currentUser) return;
        try {
            axios.post("/profile/favourite", { placeID })
            setFavourite(prev =>
                [...prev, placeID]
            );
        } catch (error) {
            console.error("An error occurred while liking the place: ", error);
        }
    }
    async function handleUnlike(placeID) {
        if (!currentUser) return;
        try {
            axios.post("/profile/unfavourite", { placeID })
            setFavourite(prev => prev.filter(id => id !== placeID));
        } catch (error) {
            console.error("An error occurred while unliking the place: ", error);
        }
    }

    const toggleLike = (placeId) => {
        if (favourite.includes(placeId)) {
            handleUnlike(placeId);
        } else {
            handleLike(placeId);
        }
    };

    const prevSlide = (index) => {
        const isFirstSlide = currentImgIndices[index] === 0;
        const newSlideIndex = isFirstSlide ? homePlaces[index].photosFile.length - 1 : currentImgIndices[index] - 1;
        setCurrentImgIndices(prevIndices => ({
            ...prevIndices,
            [index]: newSlideIndex
        }));
    }

    const nextSlide = (index) => {
        const isLastSlide = currentImgIndices[index] === homePlaces[index].photosFile.length - 1;
        const newSlideIndex = isLastSlide ? 0 : currentImgIndices[index] + 1;
        setCurrentImgIndices(prevIndices => ({
            ...prevIndices,
            [index]: newSlideIndex
        }));
    }
    // console.log(homePlaces);
    const gotoSlide = (homePlaceIndex, slideIndex) => {
        setCurrentImgIndices(prevIndices => ({
            ...prevIndices,
            [homePlaceIndex]: slideIndex
        }));
    }
    // console.log(isApiBlocked);

    // console.log(favourite);
    // console.log(homePlaces);
    return (
        <div className="grow flex flex-col min-h-screen">
            <div className="mt-3 sm:my-6 gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5">
                {homePlaces.length > 0 &&
                    homePlaces.map((homePlace, index) => (
                        <div key={index} className="rounded-2xl mb-2 sm:mb-4">
                            {homePlace.photosFile && homePlace.photosFile.length > 0 ? (
                                <div className="flex justify-center relative group">
                                    <Link to={"/place/" + homePlace.title + "/" + homePlace._id} key={homePlace._id} onClick={scrollToTop}>
                                        <img
                                            src={`${apiUrl}uploads/${homePlace.photosFile[currentImgIndices[index]]}`}
                                            alt={`homePlace-photos-${index}`}
                                            className="rounded-2xl object-cover aspect-square"
                                        />

                                    </Link>

                                  {!isApiBlocked ? ( <div className="flex absolute sm:gap-0.5 top-1 left-1 md:top-3 md:left-3 bg-white rounded-xl px-2.5 py-1 text-xs justify-between truncate hover:scale-105 transition duration-400">
                                        {homePlace.city}
                                        <img className="h-4 w-4" src={`${openWeatherUrl}${weatherData[homePlace.city].weather[0].icon}.png`} alt="weather-icon" />
                                        {weatherData[homePlace.city].main.temp && (
                                            <>{Math.floor(weatherData[homePlace.city].main.temp)}°C</>
                                        )}
                                    </div>) : null}
                                   
                                     <button className="absolute z-20 top-1 right-1 md:top-3 md:right-3 bg-transparent rounded-xl px-2.5 py-1 hover:py-1.5 hover:bg-red-500 transition duration-700" onClick={() => toggleLike(homePlace._id)}>
                                        {favourite.includes(homePlace._id) ? 
                                            (<svg width="20" height="20" viewBox="0 0 1.08 1.08" fill="none" xmlns="http://www.w3.org/2000/svg" className="hover:scale-125 transition duration-700">
                                                <path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H1.08V1.08H0V0z" />
                                                <path d="M0.337 0.18C0.201 0.18 0.09 0.291 0.09 0.427c0 0.247 0.292 0.472 0.45 0.525C0.698 0.9 0.99 0.675 0.99 0.427c0-0.137-0.111-0.247-0.247-0.247-0.084 0-0.158 0.042-0.202 0.105A0.247 0.247 0 0 0 0.337 0.18" fill="red" stroke="white" strokeWidth="0.09" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>)
                                            :
                                            (<svg width="18px" height="18px" viewBox="0 0 1.08 1.08" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                <path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H1.08V1.08H0V0z" />
                                                <path d="M0.337 0.18C0.201 0.18 0.09 0.291 0.09 0.427c0 0.247 0.292 0.472 0.45 0.525C0.698 0.9 0.99 0.675 0.99 0.427c0-0.137-0.111-0.247-0.247-0.247-0.084 0-0.158 0.042-0.202 0.105A0.247 0.247 0 0 0 0.337 0.18" fill="none" stroke="white" strokeWidth="0.09" strokeLinecap="round" strokeLinejoin="round" />
                                            </svg>)
                                        }
                                    </button>
                                                                             
                                    <div className="hidden group-hover:block absolute top-[55%] -translate-x-0 translate-y-[-50%] left-1 text-2xl rounded-full p-1.5 bg-black/15 text-white hover:text-red-300 cursor-pointer"
                                        onClick={() => prevSlide(index)}>
                                        <BsChevronCompactLeft size={18} />
                                    </div>
                                    <div className="hidden group-hover:block absolute top-[55%] -translate-x-0 translate-y-[-50%] right-1 text-2xl rounded-full p-1.5 bg-black/15 text-white hover:text-red-300 cursor-pointer"
                                        onClick={() => nextSlide(index)}>
                                        <BsChevronCompactRight size={18} />
                                    </div>
                                    <div className="hidden group-hover:flex absolute top-[84%] translate-x-0 -translate-y-[-50%] text-xl rounded-full  bg-black/5">
                                        {homePlaces[index].photosFile.length <= 5 ? (
                                            homePlaces[index].photosFile.map((slide, slideIndex) => (
                                                <div key={slideIndex} onClick={() => gotoSlide(index, slideIndex)} className=" text-white cursor-pointer flex-row space-x-1  hover:bg-red-400 rounded-full " >
                                                    <RxDotFilled />
                                                </div>
                                            ))
                                        ) : (
                                            Array.from({ length: 5 }).map((_, slideIndex) => (
                                                <div key={slideIndex} onClick={() => gotoSlide(index, slideIndex)} className=" text-white cursor-pointer flex-row space-x-1  hover:bg-red-400 rounded-full " >
                                                    <RxDotFilled />
                                                </div>
                                            ))
                                        )}
                                    </div>

                                </div>
                            ) : (
                                <div className="flex justify-center">
                                    <img
                                        src={`${apiUrl}uploads/${"no-img-default.png"}`}
                                        alt="Default"
                                        className="rounded-2xl object-cover aspect-square"
                                    />
                                </div>
                            )}

                            <Link to={"/place/" + homePlace.title + "/" + homePlace._id} key={homePlace._id} onClick={scrollToTop}>
                                <h2 className="font-medium pb-0.5 pt-3 truncate">{homePlace.address}</h2>
                            </Link>
                            <h4 className="text-sm truncate py-0.5"> {homePlace.building}
                                {homePlace.bathrooms > 1 ? (<>  |  {homePlace.bathrooms} baths </>) :
                                    (<>  |  {homePlace.bathrooms} bath</>)}
                            </h4>
                            <h4 className="text-sm truncate py-0.5">{homePlace.title}</h4>

                            <p className="text-sm font-medium py-0.5">
                                <span className="font-medium text-red-500">{`${homePlace.currency_symbol}${homePlace.price.toLocaleString()}`}</span> per {`${homePlace.durationType.toLowerCase()}`}
                            </p>


                        </div>

                    ))}
            </div>
        </div>
    );
}

export default MarketPlace;



// if (isLiked) {
//     console.log("I am here and just got unliked and I will remove like");
//     console.log(favourite);
//     const removeFavourite = [...currentUser.favourite];
//     removeFavourite.splice(index, 1);
//     setFavourite(removeFavourite);
//     console.log(favourite);
//     await axios.put('/profile/favourite', { favourite, currentUser }).then(() => {
//         console.log("This place " + likePlace + " is removed from favourites: " + favourite);

//         // console.log(currentUser.favourite);
//     }).catch(error => {
//         console.error(error);
//     });
//     // console.log("You unlike: " + index + " and removed " + homePlaces[index]._id);
// }
// else {
//     console.log("I am here and just got liked");
//     console.log(favourite);
//     setFavourite((prev) => {
//         return [...prev, likePlace]
//     });
    
//     await axios.put('/profile/favourite', { favourite, currentUser }).then(() => {
//         console.log("This place " + likePlace + " is now added to list of favourites: " + favourite);

//         // console.log(currentUser.favourite);
//     }).catch(error => {
//         console.error(error);
//     });
//     // console.log("You liked index: " + index + " and: " + homePlaces[index]._id);
// }
// const isFirstSlide = currentImgIndex === 0;
    // const newSlideIndex = isFirstSlide ? homePlaces[0].photosFile.length - 1 : currentImgIndex - 1;
    
    // setCurrentImgIndex(newSlideIndex);
