import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import {
    differenceInDays, differenceInQuarters,
    differenceInYears, differenceInMonths,
    format
} from 'date-fns';

export default function MyBookingPage() {
    const apiUrl = axios.defaults.baseURL;
    axios.defaults.withCredentials = true;
    // /bookings/:bookingTitle/:bookingID'
    // const { bookingTitle, bookingID } = useParams();
    const [bookedPlaces, setBookedPlaces] = useState([]);
    const [deleted, setdeleted] = useState(false);
    //const [totalPrice, setTotalPrice] = useState(null)

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('/bookings');
                //console.log(data);
                setBookedPlaces(data);
            } catch (error) {
                console.error("Error fetching places:", error);
            }
        };
        // Call the async function
        fetchData();

    }, [deleted]);

    async function deleteOneButton(index) {
        setdeleted(true);
        try {

            const bookedPlace = bookedPlaces[index];

            if (Object.keys(bookedPlace).length > 0) {
                await axios.put("/bookings", { bookedPlace });

                // Other asynchronous operations...

                // Handle success
            } else {
                return;
            }

        } catch (error) {
            console.error(error);
        }
        setdeleted(false);
    }

    
    return bookedPlaces && bookedPlaces?.place !== null  ? (
        <div>
            <div className="text-center">
                <Link to='/marketplace' className="inline-flex gap-1 bg-primary  py-2 px-6 rounded-xl mt-4">
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 4.5v15m7.5-7.5h-15" />
                    </svg>
                    <span className="flex"> Book a place</span>
                </Link>
            </div>
            {bookedPlaces.map((bookPlace, index) => {
                return (
                    <div className="mt-4 relative" key={index}>

                        <div className={"bg-gray-100 p-4 flex gap-4 rounded-2xl  mt-2 mb-4 shadow-lg shadow-gray-500/500 "} >
                            <div className=" w-32 h-32 bg-gray-300 grow-0 shrink-0 rounded-2xl">
                                {bookPlace && bookPlace.place.photosFile.length > 0 ? (
                                    <img src={`${apiUrl}uploads/${bookPlace.place.photosFile[0]}`} alt="Added photos"
                                        className="rounded-2xl object-cover flex h-32 w-full justify-center text-center items-center" />
                                ) : (
                                    <img
                                        src={`${apiUrl}uploads/${"no-img-default.png"}`}
                                        alt="Default" ß
                                        className="rounded-2xl object-cover aspect-square" />
                                )}
                            </div>
                            <div className=" grow-0 shrink">
                                <h2 className=" text-md font-medium flex sm:hidden "> {bookPlace.place.title.substring(0, 20)}</h2>
                                <h2 className=" md:text-xl text-md font-semibold hidden sm:flex "> {bookPlace.place.title}</h2>
                                {/* <p className=" text-sm sm:hidden flex mt-1.5">{bookPlace.place.description.substring(0, 30) + "..."} <br /> </p> */}
                                <p className=" text-sm hidden sm:flex lg:hidden mt-1.5">{bookPlace.place.description.substring(0, 100) + "..."} <br /> </p>
                                <p className=" text-sm hidden lg:flex mt-1.5">{bookPlace.place.description.substring(0, 150) + "..."} <br /> </p>
                                <div className=" sm:flex sm:gap-1 text-md my-1 items-center" >
                                    {bookPlace.place.durationType && (
                                        <div className="flex gap-1 text-md my-1 items-center">
                                            <h3 className=" text-red-600">
                                                {(() => {
                                                    const periodCal =
                                                        bookPlace.place.durationType === "Day"
                                                            ? differenceInDays(
                                                                new Date(bookPlace.moveOut),
                                                                new Date(bookPlace.moveIn)
                                                            )
                                                            : bookPlace.place.durationType === "Month"
                                                                ? differenceInMonths(
                                                                    new Date(bookPlace.moveOut),
                                                                    new Date(bookPlace.moveIn)
                                                                )
                                                                : bookPlace.place.durationType === "Quarter"
                                                                    ? differenceInQuarters(
                                                                        new Date(bookPlace.moveOut),
                                                                        new Date(bookPlace.moveIn)
                                                                    )
                                                                    : bookPlace.place.durationType === "Year"
                                                                        ? differenceInYears(
                                                                            new Date(bookPlace.moveOut),
                                                                            new Date(bookPlace.moveIn)
                                                                        )
                                                                        : null;

                                                    return periodCal === 1 ? `${periodCal} ${bookPlace.place.durationType} `
                                                        : `${periodCal} ${bookPlace.place.durationType}s `;
                                                })()}
                                            </h3>

                                        </div>
                                    )}
                                    <div className="flex gap-1 text-md my-1 items-center">
                                        <svg width="16px" height="16px" viewBox="0 0 0.4 0.4" className="sm:mx-1 items-center" xmlns="http://www.w3.org/2000/svg"><path fill="#454242" d="M0.113 0a0.014 0.014 0 0 1 0.014 0.014v0.026h0.15v-0.026a0.014 0.014 0 0 1 0.028 0v0.026H0.36a0.04 0.04 0 0 1 0.04 0.04v0.28A0.04 0.04 0 0 1 0.36 0.4H0.04a0.04 0.04 0 0 1 -0.04 -0.04V0.08a0.04 0.04 0 0 1 0.04 -0.04h0.059V0.014a0.014 0.014 0 0 1 0.014 -0.014ZM0.028 0.155v0.205a0.012 0.012 0 0 0 0.012 0.012h0.32a0.012 0.012 0 0 0 0.012 -0.012V0.155L0.028 0.155Zm0.105 0.138v0.033H0.1v-0.033h0.033Zm0.083 0v0.033H0.183v-0.033h0.033Zm0.083 0v0.033h-0.033v-0.033H0.3Zm-0.167 -0.08v0.033H0.1v-0.033h0.033Zm0.083 0v0.033H0.183v-0.033h0.033Zm0.083 0v0.033h-0.033v-0.033H0.3ZM0.099 0.068H0.04a0.012 0.012 0 0 0 -0.012 0.012v0.047l0.344 0V0.08a0.012 0.012 0 0 0 -0.012 -0.012h-0.054v0.019a0.014 0.014 0 0 1 -0.028 0v-0.019H0.127v0.018a0.014 0.014 0 0 1 -0.028 0v-0.018Z" /></svg>
                                        <h3>{format(new Date(bookPlace.moveIn), "yyyy-MM-dd")} </h3>
                                    </div>

                                    <svg width="15px" height="15px" viewBox="0 0 7.5 7.5" className="hidden sm:flex" fill="none" xmlns="http://www.w3.org/2000/svg"><path d="M0.735 3.014a42.006 42.006 0 0 1 2.277 -0.062c1.134 0 2.028 0.062 2.262 0.062" stroke="#000000" strokeOpacity="0.9" strokeWidth="0.24" strokeLinecap="round" strokeLinejoin="round" /><path d="M4.468 2.22c0.203 0.057 0.665 0.637 0.788 0.698 0.123 0.061 -0.406 0.754 -0.649 0.877" stroke="#000000" strokeOpacity="0.9" strokeWidth="0.24" strokeLinecap="round" strokeLinejoin="round" /></svg>
                                    <div className="flex gap-1 text-md my-1 items-center" >
                                        <svg width="16px" height="16px" viewBox="0 0 0.4 0.4" className="sm:mx-1 items-center" xmlns="http://www.w3.org/2000/svg"><path fill="#454242" d="M0.113 0a0.014 0.014 0 0 1 0.014 0.014v0.026h0.15v-0.026a0.014 0.014 0 0 1 0.028 0v0.026H0.36a0.04 0.04 0 0 1 0.04 0.04v0.28A0.04 0.04 0 0 1 0.36 0.4H0.04a0.04 0.04 0 0 1 -0.04 -0.04V0.08a0.04 0.04 0 0 1 0.04 -0.04h0.059V0.014a0.014 0.014 0 0 1 0.014 -0.014ZM0.028 0.155v0.205a0.012 0.012 0 0 0 0.012 0.012h0.32a0.012 0.012 0 0 0 0.012 -0.012V0.155L0.028 0.155Zm0.105 0.138v0.033H0.1v-0.033h0.033Zm0.083 0v0.033H0.183v-0.033h0.033Zm0.083 0v0.033h-0.033v-0.033H0.3Zm-0.167 -0.08v0.033H0.1v-0.033h0.033Zm0.083 0v0.033H0.183v-0.033h0.033Zm0.083 0v0.033h-0.033v-0.033H0.3ZM0.099 0.068H0.04a0.012 0.012 0 0 0 -0.012 0.012v0.047l0.344 0V0.08a0.012 0.012 0 0 0 -0.012 -0.012h-0.054v0.019a0.014 0.014 0 0 1 -0.028 0v-0.019H0.127v0.018a0.014 0.014 0 0 1 -0.028 0v-0.018Z" /></svg>
                                        <h3>{format(new Date(bookPlace.moveOut), "yyyy-MM-dd")} </h3>
                                    </div>
                                </div>
                                <div className="flex  text-md my-2 items-center ">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 30 30" strokeWidth="1.5" stroke="currentColor" className="w-5 h-5 justify-center items-center">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M2.25 18.75a60.07 60.07 0 0115.797 2.101c.727.198 1.453-.342 1.453-1.096V18.75M3.75 4.5v.75A.75.75 0 013 6h-.75m0 0v-.375c0-.621.504-1.125 1.125-1.125H20.25M2.25 6v9m18-10.5v.75c0 .414.336.75.75.75h.75m-1.5-1.5h.375c.621 0 1.125.504 1.125 1.125v9.75c0 .621-.504 1.125-1.125 1.125h-.375m1.5-1.5H21a.75.75 0 00-.75.75v.75m0 0H3.75m0 0h-.375a1.125 1.125 0 01-1.125-1.125V15m1.5 1.5v-.75A.75.75 0 003 15h-.75M15 10.5a3 3 0 11-6 0 3 3 0 016 0zm3 0h.008v.008H18V10.5zm-12 0h.008v.008H6V10.5z" />
                                    </svg>

                                    <h3>Total price: {bookPlace.place.currency_symbol}<span className=" items-center  text-red-600">
                                        {bookPlace.place.durationType && (
                                            (() => {
                                                const priceCal =
                                                    bookPlace.place.durationType === "Day"
                                                        ? differenceInDays(
                                                            new Date(bookPlace.moveOut),
                                                            new Date(bookPlace.moveIn)
                                                        )
                                                        : bookPlace.place.durationType === "Month"
                                                            ? differenceInMonths(
                                                                new Date(bookPlace.moveOut),
                                                                new Date(bookPlace.moveIn)
                                                            )
                                                            : bookPlace.place.durationType === "Quarter"
                                                                ? differenceInQuarters(
                                                                    new Date(bookPlace.moveOut),
                                                                    new Date(bookPlace.moveIn)
                                                                )
                                                                : bookPlace.place.durationType === "Year"
                                                                    ? differenceInYears(
                                                                        new Date(bookPlace.moveOut),
                                                                        new Date(bookPlace.moveIn)
                                                                    )
                                                                    : null;
                                                return priceCal !== null ? `${(priceCal * bookPlace.place.price).toLocaleString()}` : null;
                                            })()

                                        )}
                                    </span>
                                    </h3>
                                </div>
                                <Link to={`/place/${bookPlace.place.title}/${bookPlace.place._id}`} className="inline-flex items-center underline text-lg my-3 gap-0.5" >
                                    <span>Read more</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 hidden sm:flex">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </Link>

                            </div>


                            <button className="bg-primary flex gap-1 items-center justify-center absolute bottom-2 right-2 w-14 h-7 sm:h-auto sm:w-auto z-10 py-1 px-2.5 rounded-xl "
                                onClick={() => deleteOneButton(index)}>
                                <svg width="15px" height="15px" viewBox="0 0 0.375 0.375" xmlns="http://www.w3.org/2000/svg"><path fill="#555" d="M0.171 0h0.037c0.015 0.002 0.028 0.008 0.037 0.02 0.008 0.009 0.013 0.021 0.013 0.033h0.103c0.007 0 0.013 0.006 0.013 0.013a0.013 0.013 0 0 1 -0.013 0.013H0.327v0.218c0 0.048 -0.018 0.078 -0.057 0.078H0.102C0.063 0.376 0.045 0.345 0.045 0.297V0.078H0.013A0.013 0.013 0 0 1 0 0.066C0 0.058 0.006 0.053 0.013 0.053h0.103A0.058 0.058 0 0 1 0.125 0.023 0.061 0.061 0 0 1 0.171 0Zm0.131 0.078H0.071v0.218c0 0.035 0.01 0.053 0.031 0.053h0.169C0.292 0.349 0.302 0.332 0.302 0.297V0.078ZM0.126 0.119C0.133 0.119 0.138 0.125 0.138 0.133V0.283a0.013 0.013 0 0 1 -0.013 0.013A0.013 0.013 0 0 1 0.113 0.283V0.133C0.113 0.125 0.119 0.119 0.126 0.119Zm0.051 0c0.007 0 0.013 0.006 0.013 0.013V0.283a0.013 0.013 0 0 1 -0.013 0.013A0.013 0.013 0 0 1 0.164 0.283V0.133C0.164 0.125 0.17 0.119 0.177 0.119Zm0.051 0c0.007 0 0.013 0.006 0.013 0.013V0.283a0.013 0.013 0 0 1 -0.013 0.013A0.013 0.013 0 0 1 0.215 0.283V0.133C0.215 0.125 0.221 0.119 0.228 0.119ZM0.172 0.026C0.16 0.027 0.152 0.031 0.147 0.038 0.143 0.044 0.141 0.048 0.141 0.053H0.233C0.233 0.047 0.231 0.042 0.227 0.037A0.032 0.032 0 0 0 0.206 0.026H0.171Z" /></svg>
                                <span className="hidden sm:flex">delete</span>
                            </button>

                        </div>

                    </div>

                )

            })}
        </div>
    ) : null
}