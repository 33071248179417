import React from "react";
import { Link } from "react-router-dom";

function FAQs() {
   
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className=" inset-0 bg-white min-h-screen z-50 absolute w-full  -top-20 " >
            <div className=" m-3 p-2">
                <h1 className=" font-bold text-2xl text-primary">
                    <Link to={"/"} className=" transition hover:opacity-75" onClick={scrollToTop}>Nookily</Link>
                </h1>
            </div>
            <div className=" p-12 sm:p-16 md:p-28  bg-primary">
                <h1 className=" text-white font-bold text-xl text-left md:text-6xl">FAQs</h1>
            </div>
            <div className="p-12  md:px-28 md:py-10 ">
                <p className="py-2 text-lg">Frequently Asked Questions (FAQs)</p>

                <ol class=" pl-4 gap">
                    <li className="font-medium py-2">What is Nookily?</li>
                    <ul>
                        <li>Nookily is a platform that connects homeowners, landlords, and property owners with individuals seeking to rent, sublet, and lease living spaces for short and long-term periods. Whether you're looking for a cozy urban apartment or a picturesque countryside retreat, Nookily helps you find your perfect living nook.</li>
                    </ul>
                    <li className="font-medium py-2">How does Nookily work?</li>
                    <ul className="list-disc" >
                        <li>Property owners can create listings for their available spaces, including apartments, buildings, or shared rooms, on the Nookily website.</li>
                        <li>Renters can explore these listings, connect with property owners, and secure accommodations through the platform.</li>
                        <li>Nookily ensures transparent communication, and a seamless rental experience for both property owners and renters.</li>
                   
                    </ul>
                    <li className="font-medium py-2">How do I create a listing on Nookily?</li>
                    <ul className="pb-2">
                        <li>Creating a listing on Nookily is easy:</li>
                    </ul>
                    <ul className="list-disc" >
                    <li><Link target="_blank" to="/register" className=" underline text-blue-500 transition hover:opacity-75">Sign up</Link> for a Nookily account</li>
                    </ul>
                    <ul className="list-disc" >
                        <li>Provide accurate and detailed information about your property, including photos, amenities, and rental terms. <Link target="_blank" to={"/account/places/new"} className="underline text-blue-500 transition hover:opacity-75">List Your Property</Link></li>
                    </ul>
                    <ul className="list-disc" >
                        <li>Publish your listing and start receiving inquiries from potential renters.</li>
                    </ul>
                    <li className="font-medium py-2">What types of properties can I find on Nookily?</li>
                    <ul>
                        <li>Nookily offers a diverse range of properties, including apartments, houses, condos, villas, and shared rooms, hotel reservations, available for short and long-term rentals. Whether you're looking for an urban retreat or a countryside getaway, Nookily has something for everyone.</li>
                    </ul>
                    <li className="font-medium py-2">Is Nookily available in my area?</li>
                    <ul>
                        <li>Nookily is continuously expanding its reach to new regions and markets. While we may not be available in every area at the moment, we're working hard to bring Nookily to more locations. Keep an eye on our website for updates on our availability.</li>
                    </ul>
                    <li className="font-medium py-2">What is Nookily's nondiscrimination policy?</li>
                    <ul>
                        <li>Nookily is committed to providing an inclusive and welcoming environment for all users. We do not discriminate on the basis of race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or any other protected characteristic. Discrimination of any kind is not tolerated on the Nookily platform.</li>
                    </ul>
                    <li className="font-medium py-2">How can I report discrimination or inappropriate behavior on Nookily?</li>
                    <ul>
                        <li>If you experience or witness discrimination or inappropriate behavior on the Nookily platform, please report it to us immediately. You can contact our support team at <Link to="mailto:contact@nookily,com" className=" underline text-blue-500 transition hover:opacity-75"> contact@nookily.com</Link> or use the reporting tools available on the website. We take reports of discrimination and inappropriate behavior seriously and will take appropriate action in accordance with our policies.</li>
                    </ul>
                    <li className="font-medium py-2">How can I contact Nookily for support or assistance?</li>
                    <ul>
                        <li>If you have any questions, concerns, or need assistance, our support team is here to help. You can reach us via email at <Link to="mailto:contact@nookily,com" className=" underline text-blue-500 transition hover:opacity-75"> contact@nookily.com</Link> or through the contact form on our website. We strive to respond to all inquiries promptly and provide the assistance you need.</li>
                    </ul>
                </ol>
            </div>
        </div>
    )
}
export default FAQs;