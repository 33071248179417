import React from "react";
import { Link } from "react-router-dom";

function TermsOfUseAndService() {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className=" inset-0 bg-white min-h-screen z-50 absolute w-full  -top-20 " >
            <div className=" m-3 p-2">
                <h1 className=" font-bold text-2xl text-primary">
                    <Link to={"/"} className=" transition hover:opacity-75" onClick={scrollToTop}>Nookily</Link>
                </h1>
            </div>
            <div className=" p-12 sm:p-16 md:p-28  bg-primary">
                <h1 className=" text-white font-bold text-4xl text-left md:text-6xl">Privacy Policy</h1>
            </div>
            <div className="p-12  md:px-28 md:py-10">
                <p className="py-2 text-lg">Update to our privacy policy</p>
                <p className="pb-1">This Privacy Policy ("Policy") describes how Nookily Technology Limited ("Nookily," "we," "us," or "our") collects, uses, and protects the personal information of users of the Nookily website (the "Website"). By accessing or using the Website, you agree to be bound by this Policy. If you do not agree to this Policy, you may not access or use the Website.</p>

                <ol class=" pl-4 gap">
                    <li className="font-medium py-2">Information We Collect</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600  font-medium">1.1. Personal Information:</span> We may collect personal information that you provide to us when using the Website, such as your name, email address, phone number, and payment information.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600  font-medium">1.2. Usage Information:</span> We may collect information about your use of the Website, including your IP address, browser type, device type, and operating system.</li>
                    </ul>
                   
                    <li className="font-medium py-2">Use of Information</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600  font-medium">2.1. Personalization:</span> We may use the information we collect to personalize your experience on the Website and to provide you with tailored content and recommendations.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">2.2. Communication:</span> We may use your contact information to communicate with you about your account, transactions, and updates to the Website.</li>
                    </ul>
                    <li className="font-medium py-2">Sharing of Information</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">3.1. Service Providers:</span> We may share your personal information with third-party service providers that assist us in providing and improving the Website, such as payment processors and analytics providers.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">3.2. Legal Compliance:</span> We may disclose your personal information as required by law or in response to legal process, such as a court order or subpoena.</li>
                    </ul>
                    <li className="font-medium py-2">Data Security</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">4.1. Security Measures:</span> We take reasonable measures to protect the security of your personal information, including encryption, access controls, and regular security assessments.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">4.2.  No Guarantee:</span> However, no method of transmission over the Internet or electronic storage is completely secure, and we cannot guarantee the absolute security of your personal information.</li>
                    </ul>
                    <li className="font-medium py-2">Your Choices</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">5.1. Account Information:</span> You may update or correct your account information at any time by logging into your account settings.</li>
                    </ul>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">5.2. Marketing Communications:</span> You may opt out of receiving marketing communications from us by following the instructions provided in such communications or by contacting us directly.</li>
                    </ul>
                    <li className="font-medium py-2">Nondiscrimination Policy</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">6.1. Nondiscrimination:</span> Nookily does not discriminate on the basis of race, color, religion, gender, gender identity or expression, sexual orientation, national origin, genetics, disability, age, or any other protected characteristic.</li>
                    </ul>
                    <li className="font-medium py-2">Children's Privacy</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">7.1. Children Under 13:</span> The Website is not intended for children under 13 years of age, and we do not knowingly collect personal information from children under 13. If we learn that we have collected personal information from a child under 13, we will delete such information.</li>
                    </ul>
                    <li className="font-medium py-2">Changes to this Policy</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">8.1. Changes:</span> We reserve the right to modify or amend this Policy at any time. Any changes to this Policy will be effective immediately upon posting of the revised Policy on the Website.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">8.2. Notification</span> Notification: We will notify users of material changes to this Policy by email or by posting a notice on the Website. Your continued use of the Website after such changes will constitute your acceptance of the revised Policy.</li>
                    </ul>
                </ol>

                <p className=" py-2">All content, including text, images, graphics, and other materials on the Nookily website are subject to copyright and other intellectual property rights under applicable laws, unless otherwise stated. These materials may not be reproduced, distributed, modified, or reposted to other websites without the express written permission of Nookily.</p>
                 <div className=" pb-8">
                    <p className="  py-2 ">For inquiries regarding any questions or concerns about these Terms, please contact us at <Link to="mailto:contact@nookily,com" className=" underline text-blue-500 transition hover:opacity-75"> contact@nookily.com</Link> </p>
                </div>

            </div>
        </div>
    )
}
export default TermsOfUseAndService;