import React from "react";
import { Link } from "react-router-dom";

function TermsOfUseAndService() {

    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <div className=" inset-0 bg-white min-h-screen z-50 absolute w-full  -top-20 " >
            <div className=" m-3 p-2">
                <h1 className=" font-bold text-2xl text-primary">
                    <Link to={"/"} className=" transition hover:opacity-75" onClick={scrollToTop}>Nookily</Link>
                </h1>
            </div>
            <div className=" p-12 sm:p-16 md:p-28  bg-primary">
                <h1 className=" text-white font-bold text-xl text-left md:text-6xl"> Nookily general Terms & Conditions of use</h1>
            </div>
            <div className="p-12  md:px-28 md:py-10 ">
                <p className="py-2 text-lg">Trademark Notice</p>
                <p className="pb-1">These Terms of Use ("Terms") govern your access to and use of the Nookily website (the "Website") operated by Nookily Technology Limited ("Nookily," "we," "us," or "our"). By accessing or using the Website, you agree to be bound by these Terms. If you do not agree to these Terms, you may not access or use the Website.</p>

                <ol class=" pl-4 gap">
                    <li className="font-medium py-2">Use of the Website</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">1.1. Eligibility:</span> You must be at least 18 years old and have the legal capacity to enter into these Terms to use the Website. By accessing or using the Website, you represent and warrant that you meet these eligibility requirements.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">1.2. License:</span>Subject to these Terms, Nookily grants you a limited, non-exclusive, non-transferable, and revocable license to access and use the Website for your personal and non-commercial purposes.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">1.3. Prohibited Conduct:</span> You agree not to:</li>
                        <ul className="list-disc pl-4 " >
                            <li className="py-0.5">Use the Website for any illegal or unauthorized purpose.</li>
                            <li className="py-0.5">Interfere with or disrupt the operation of the Website.</li>
                            <li className="py-0.5">Attempt to gain unauthorized access to the Website or its related systems or networks.</li>
                            <li className="py-0.5">Modify, adapt, translate, or reverse engineer any part of the Website.</li>

                        </ul>
                    </ul>
                    <li className="font-medium py-2">User Accounts</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">2.1. Registration:</span> In order to access certain features of the Website, you may be required to create a user account. You agree to provide accurate, current, and complete information during the registration process and to update such information as necessary to keep it accurate, current, and complete.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">2.2. Account Security:</span> You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify Nookily immediately of any unauthorized use of your account or any other breach of security.</li>
                    </ul>
                    <li className="font-medium py-2">Listings and Transactions</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">3.1. Property Listings:</span> Nookily may allow users to create and publish listings for rental properties ("Listings") on the Website. You agree to provide accurate and up-to-date information when creating Listings and to comply with all applicable laws and regulations.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">3.2. Transactions:</span> Any transactions or agreements entered into between users of the Website are solely between the parties involved. Nookily is not a party to any such transactions and does not guarantee the accuracy, completeness, or legality of Listings or user content.</li>
                    </ul>
                    <li className="font-medium py-2">Intellectual Property</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">4.1. Ownership:</span> All content and materials on the Website, including text, images, graphics, logos, and software, are the intellectual property of Nookily or its licensors and are protected by copyright and other intellectual property laws.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">4.2. Trademarks:</span> The Nookily name, logo, and any other trademarks displayed on the Website are the registered or unregistered trademarks of Nookily. You may not use these trademarks without prior written permission from Nookily.</li>
                    </ul>
                    <li className="font-medium py-2">Privacy Policy</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">5.1. Privacy:</span> Your use of the Website is subject to our Privacy Policy, which is incorporated into these Terms by reference. By using the Website, you consent to the collection, use, and disclosure of your personal information as described in the Privacy Policy.</li>
                    </ul>
                    <li className="font-medium py-2">Disclaimer of Warranties; Limitation of Liability</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">6.1. Disclaimer:</span> The Website is provided on an "as is" and "as available" basis, without any warranties of any kind, express or implied. Nookily disclaims all warranties, including but not limited to warranties of merchantability, fitness for a particular purpose, and non-infringement.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">6.2. Limitation of Liability:</span> In no event shall Nookily be liable for any indirect, incidental, special, consequential, or punitive damages arising out of or in connection with your use of the Website, whether based on warranty, contract, tort (including negligence), or any other legal theory.</li>
                    </ul>
                    <li className="font-medium py-2">Governing Law; Dispute Resolution</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">7.1. Governing Law:</span> These Terms shall be governed by and construed in accordance with the laws of [Jurisdiction], without regard to its conflict of law provisions.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">7.2. Dispute Resolutiony:</span> Any dispute arising out of or relating to these Terms or your use of the Website shall be resolved exclusively through binding arbitration conducted by a single arbitrator in accordance with the rules of the [Arbitration Association].</li>
                    </ul>
                    <li className="font-medium py-2">Modifications to the Terms</li>
                    <ul className="pb-1">
                        <li><span className="!text-gray-600 font-medium">8.1. Changes:</span> Nookily reserves the right to modify or amend these Terms at any time. Any changes to these Terms will be effective immediately upon posting of the revised Terms on the Website.</li>
                    </ul>
                    <ul className="py-1">
                        <li><span className="!text-gray-600 font-medium">8.2. Notification:</span> Notification: Nookily will notify users of material changes to these Terms by email or by posting a notice on the Website. Your continued use of the Website after such changes will constitute your acceptance of the revised Terms.</li>
                    </ul>
                </ol>

                <p className=" py-2">All content, including text, images, graphics, and other materials on the Nookily website are subject to copyright and other intellectual property rights under applicable laws, unless otherwise stated. These materials may not be reproduced, distributed, modified, or reposted to other websites without the express written permission of Nookily.</p>
                 <div className=" pb-8">
                    <p className="  py-2 ">For inquiries regarding any questions or concerns about these Terms, please contact us at <Link to="mailto:contact@nookily,com" className=" underline text-blue-500 transition hover:opacity-75"> contact@nookily.com</Link> </p>
                </div>

            </div>
        </div>
    )
}
export default TermsOfUseAndService;