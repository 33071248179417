import { Link } from "react-router-dom";   //  Navigate, useParams You can use it to retrieve route parameters from the component rendered by the matching route


export default function PlaceNotFound() {
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }
    return (
        <div className=" grow flex items-center justify-around  flex-col min-h-screen ">
            <div class="grid place-content-center justify-items-center w-full ">
                <div class=" justify-center text-center md:text-center p-4">
                    <div class="text-6xl font-medium text-gray-600">404  </div>
                    <div class="text-lg md:text-xl font-medium mb-4 text-gray-500">
                    <br />  Oops. This page has gone missing.
                    </div>
                    <div class="text-lg mb-8 text-gray-600">
                        Place does not exist
                    </div>
                    <div className="flex justify-center ">
                        <Link to="/"  class="  justify-center place-content-center bg-primary p-2 w-32 text-white rounded-2xl" 
                        onClick={scrollToTop} > 
                        Go Home    
                        </Link>
                    </div>
                </div>

            </div>
        </div>
    )
}