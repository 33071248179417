import React, { useEffect, useContext, useState, } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { UserContext } from "../UserContext.jsx";
import { format, parseISO } from "date-fns";


export default function MyProfilePage() {
    // const apiUrl = axios.defaults.baseURL;
    // axios.defaults.withCredentials = true;
    // const [userData, setUserDate] = useState({})
    const { currentUser, } = useContext(UserContext);
    const [userInfo, setUserInfo] = useState({
        firstName: "",
        lastName: "",
        phoneNumber1: "",
        address: "",
        country: "",
        city: "",
        postalCode: "",
        // password: "",
    })

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('/profile');
                const {
                    firstName,
                    lastName,
                    username,
                    birthday,
                    phoneNumber1,
                    address,
                    country,
                    city,
                    postalCode,
                    // password,
                } = data
                // setUserDate(data)
                setUserInfo(prev => ({
                    ...prev,
                    firstName,
                    lastName,
                    username,
                    birthday,
                    phoneNumber1,
                    address,
                    country,
                    city,
                    postalCode,
                    // password,
                }));


            } catch (error) {
                console.error("Error fetching places:", error);

            }
        };

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentUser]);

    function handleChange(e) {
        const { name, value } = e.target;
        setUserInfo(prev => {
            return {
                ...prev,
                [name]: value
            }
        });
        // console.log(userInfo);
    }


    async function handleSubmit(e) {
        // e.preventDefault();
        const trial = { userInfo }
        await axios.put('/profile', { ...trial });

    }

    const dobConversion =  () => {
        try {
            if (!userInfo || !userInfo.birthday) {
                throw new Error("Invalid birthday value");
            }
            const parsedDate = parseISO(userInfo.birthday);
            if (isNaN(parsedDate)) {
                throw new Error("Invalid Date");
            }
            return format(parsedDate, 'dd-MM-yyyy');
        } catch (error) {
            console.error("Error in DobConversion:", error);
            return null;
        }
    };
    
  
    return (

        <div className="bg-white w-full flex flex-col gap-5 px-3 md:px-16 lg:px-28 md:flex-row text-[#161931]">
            <aside className="hidden py-4 md:w-1/3 lg:w-1/4 md:block">
                <div className="sticky flex flex-col gap-2 p-4 text-sm border-r border-red-100 top-12">

                    <h2 className="pl-3 mb-4 text-2xl font-semibold">Settings</h2>

                    <Link href="#" className="flex items-center px-3 py-2.5 font-bold bg-white  border rounded-full">
                        Pubic Profile
                    </Link>
                    <Link href="#"
                        className="flex items-center px-3 py-2.5 font-semibold  hover:hover:border hover:rounded-full">
                        Account Settings
                    </Link>
                    <Link href="#"
                        className="flex items-center px-3 py-2.5 font-semibold hover:hover:border hover:rounded-full  ">
                        Notifications
                    </Link>
                    <Link href="#"
                        className="flex items-center px-3 py-2.5 font-semibold hover:hover:border hover:rounded-full  ">
                        PRO Account
                    </Link>
                </div>
            </aside>
            <main className="w-full min-h-screen py-1 md:w-2/3 lg:w-3/4">
                <div className="p-2 md:p-4">
                    <div className="w-full px-6 pb-8 mt-8 sm:max-w-xl sm:rounded-lg">
                        <h2 className="pl-6 text-2xl font-bold sm:text-xl">Public Profile</h2>

                        <div className="grid max-w-2xl mx-auto mt-8">
                            <div className="flex flex-col items-center space-y-5 sm:flex-row sm:space-y-0">

                                <img className="object-cover w-40 h-40 p-1 rounded-full ring-2 ring-red-300 dark:ring-red-500"
                                    src="https://images.unsplash.com/photo-1438761681033-6461ffad8d80?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8MTB8fGZhY2V8ZW58MHx8MHx8fDA%3D&auto=format&fit=crop&w=500&q=60"
                                    alt="Bordered avatar" />

                                <div className="flex flex-col space-y-5 sm:ml-8">
                                    <button type="button"
                                        className="py-3.5 px-7 text-base font-medium text-red-100 focus:outline-none bg-[#202142] rounded-lg border border-red-200 hover:bg-red-500 focus:z-10 focus:ring-4 focus:ring-red-200 ">
                                        Change picture
                                    </button>
                                    <button type="button"
                                        className="py-3.5 px-7 text-base font-medium focus:outline-none bg-white rounded-lg border border-red-200 hover:bg-red-100 hover:text-[#202142] focus:z-10 focus:ring-4 focus:ring-red-200 ">
                                        Delete picture
                                    </button>
                                </div>
                            </div>

                            <div className="items-center mt-8 sm:mt-14 text-[#202142]">
                                <form onSubmit={handleSubmit}>
                                    <p className="text-gray-500 text-sm pb-2"><sup>*</sup>you cannot edit your email and date of birth </p>
                                    <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label className=" mb-2 text-sm font-medium">Your first name </label>
                                            <input type="text"
                                                className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                                placeholder="first name"
                                                name="firstName"
                                                value={userInfo.firstName}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="w-full">
                                            <label className=" mb-2 text-sm font-medium">Your last name</label>
                                            <input type="text"
                                                className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                                placeholder="last name"
                                                name="lastName"
                                                value={userInfo.lastName}
                                                onChange={handleChange} />
                                        </div>

                                    </div>

                                    <div className="mb-1 sm:mb-6">
                                        <label className="block mb-2 text-sm font-medium">Phone number</label>
                                        <input type="text"
                                            // pattern="^([0|\+[0-9]{1,5})?([1-9][0-9]{9})$ "
                                            className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                            placeholder="phone number"
                                            name="phoneNumber1"
                                            value={userInfo.phoneNumber1}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="mb-1 sm:mb-6">
                                        <label className="block mb-2 text-sm font-medium">Address</label>
                                        <input type="text"
                                            className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                            placeholder="address"
                                            name="address" autoComplete="street-address"
                                            value={userInfo.address}
                                            onChange={handleChange}
                                        />
                                    </div>
                                    <div className="flex flex-col items-center w-full mb-2 space-x-0 space-y-2 sm:flex-row sm:space-x-4 sm:space-y-0 sm:mb-6">
                                        <div className="w-full">
                                            <label className=" mb-2 text-sm font-medium">Country</label>
                                            <input type="text"
                                                className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                                placeholder="country"
                                                name="country" autoComplete="country-name"
                                                value={userInfo.country}
                                                onChange={handleChange}
                                            />
                                        </div>

                                        <div className="w-full">
                                            <label className=" mb-2 text-sm font-medium">City</label>
                                            <input type="text"
                                                className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                                placeholder="city"
                                                name="city" autoComplete="country"
                                                value={userInfo.city}
                                                onChange={handleChange} />
                                        </div>
                                        <div className="w-full">
                                            <label className=" mb-2 text-sm font-medium">Postal code</label>
                                            <input type="text"
                                                className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                                placeholder="postal code"
                                                name="postalCode" autoComplete="postal-code"
                                                value={userInfo.postalCode}
                                                onChange={handleChange} />
                                        </div>

                                    </div>

                                    {/* <div className="mb-1 sm:mb-6">
                                        <label className="block mb-2 text-sm font-medium">Password</label>
                                        <input type="password" pattern="^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$"
                                            className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                            placeholder="enter new password"
                                            name="password" autoComplete="new-password"
                                            value={userInfo.password}
                                            onChange={handleChange}
                                            onCut={preventCopyPaste} onCopy={preventCopyPaste} onPaste={preventCopyPaste} onDrag={preventCopyPaste}
                                        />
                                    </div> */}

                                    {/* <div className="mb-2 sm:mb-6">
                                    <input type="password"
                                        className="bg-red-50 border border-red-300 text-sm rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                        placeholder="re-enter password"
                                        name="confirmPassword"
                                        value={userInfo.confirmPassword}
                                        onChange={handleChange} />          
                                </div> */}

                                    <div className="mb-2 sm:mb-6">
                                        <label className="block mb-2 text-sm font-medium">Your email</label>
                                        <input type="email"
                                            className="bg-red-50 border border-red-300 text-sm !text-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5 "
                                            placeholder="your-email@mail.com"
                                            disabled="true"
                                            value={userInfo.username} />
                                    </div>

                                    <div className="mb-2 sm:mb-6">
                                        <label className="block mb-2 text-sm font-medium ">Date of birth</label>
                                        <input type="text" id="profession"
                                            className="bg-red-50 border border-red-300 text-sm !text-gray-300 rounded-lg focus:ring-red-500 focus:border-red-500 block w-full p-2.5  "
                                            placeholder="D.O.B"
                                            disabled="true"
                                            value={dobConversion()} 

                                            />
                                    </div>

                                    {/* <div className="mb-6">
                                    <label className="block mb-2 text-sm font-medium">Bio</label>
                                    <textarea id="message" rows="4"
                                        className="block p-2.5 w-full text-sm bg-red-50 rounded-lg border border-red-300 focus:ring-red-500 focus:border-red-500 "
                                        placeholder="Write your bio here..."></textarea>
                                </div> */}

                                    <div className="flex justify-end">
                                        <button type="submit"
                                            className="text-white bg-primary shadow-md shadow-primary-500/500 hover:bg-rose-600 font-medium rounded-lg text-sm w-full sm:w-auto px-5 py-2.5 text-center">Save</button>
                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
        </div>
    )
}

// {_id ,
//     firstName,
//     lastName ,
//     birthday ,
//     username ,
//     createdAt ,
//     updatedAt }

