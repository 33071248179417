import React, { createContext, useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export const LoginPathContext = createContext({});

export const LoginPathProvider = ({ children,  }) => {
  const [contextValue, setContextValue] = useState([]);
  const { pathname } = useLocation(); // to determina the location of a path

  useEffect(() => {
    setContextValue(prev => {
      return [
        ...prev,
        pathname
      ];
    });
  }, [pathname]);

  return (
    <LoginPathContext.Provider value={{ contextValue, setContextValue }}>
      {children}
    </LoginPathContext.Provider>
  );
};


