import React, { useEffect, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import IndexPage from "./IndexPage";
// import { SearchContext } from '../SearchContext';



function MarketPlaceSearch({ data }) {
    const apiUrl = axios.defaults.baseURL;
    const [searchData, setSearchData] = useState([]);
    // const [dataLoaded, setDataLoaded] = useState(false);
    // const [error, setError] = useState(false);
    // const { searchedData } = useContext(SearchContext)

    const navigate = useNavigate();
    const { search } = useLocation()
    // console.log(searchedData);

    const handleGoBack = async () => {
        setSearchData([]);
        navigate('/marketplace'); // Navigate to the previous page

    };
    console.log(search);
    useEffect(() => {
        const fetchData = async () => {
            axios.get(`/marketplace/search${search}`).then(({ data }) => {
                setSearchData(data);


                // setDataLoaded(true);
                // console.log(data);

                if (data === null && data === undefined) {
                    return
                    // setError(true);
                }

            }).catch(error => {
                // Handle error, log it, or display an error message
                console.error("Error fetching places:", error);
                // setError(true);
                //setCurrentUser(null);
            });

        };
        fetchData()
    }, [search])
    // console.log(searchQuery);
    function scrollToTop() {
        window.scrollTo({ top: 0, behavior: "smooth" })
    }


    return searchData !== undefined && searchData.length > 0 ? (
        <div className="grow flex flex-col min-h-screen" >
            <button className="bg-transparent " onClick={handleGoBack}>
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                    <path strokeLinecap="round" strokeLinejoin="round" d="M11.25 9l-3 3m0 0l3 3m-3-3h7.5M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
            </button>
            <div className="flex justify-center items-center sm:my-4 ">
                <h1>{searchData.length === 1 ? (<>{searchData.length} no of place found</>)
                    : <>{searchData.length} nos of places found</>
                } </h1>
            </div>

            <div className="mt-3 sm:my-6 gap-4 grid grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 grow">
                {searchData.length > 0 &&
                    searchData.map((homePlace) => (
                        <Link to={"/place/" + homePlace.title + "/" + homePlace._id} key={homePlace._id} onClick={scrollToTop}>
                            <div className="rounded-2xl mb-2 sm:mb-4">
                                {homePlace.photosFile && homePlace.photosFile.length > 0 ? (
                                    <div className="flex justify-center">
                                        <img
                                            src={`${apiUrl}uploads/${homePlace.photosFile[0]}`}
                                            alt="homePlace-photos"
                                            className="rounded-2xl object-cover aspect-square"
                                        />
                                    </div>
                                ) : (
                                    // Default image or placeholder
                                    <div className="flex justify-center">
                                        <img
                                            src={`${apiUrl}uploads/${"no-img-default.png"}`}
                                            alt="Default"
                                            className="rounded-2xl object-cover aspect-square"
                                        />
                                    </div>
                                )}
                                <h2 className="font-medium mt-2 truncate">{homePlace.address}</h2>
                                <h3 className="text-sm truncate"> {homePlace.building}
                                    {homePlace.bathrooms > 1 ? (<>  |  {homePlace.bathrooms} baths </>) :
                                        (<>  |  {homePlace.bathrooms} bath</>)}
                                </h3>
                                <h3 className="text-sm truncate">{homePlace.title}</h3>

                                <p className="text-sm font-medium">
                                    <span className="font-medium text-red-500">{`${homePlace.currency_symbol}${homePlace.price.toLocaleString()}`}</span> per {`${homePlace.durationType.toLowerCase()}`}
                                </p>
                            </div>
                        </Link>
                    ))}
            </div>

        </div>
    ) : (
        // setTimeout(() => {

        // }, timeout)
        <div className="grow flex flex-col min-h-screen" >
            <div className="flex justify-center items-center m-8 ">
                <h1>No available properties for your search.</h1>
            </div>
            <IndexPage />
        </div>

    )
}

export default MarketPlaceSearch;


