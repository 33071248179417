import React, { useEffect, useState, useContext } from "react";
import { Link } from "react-router-dom";   //  Navigate, useParams You can use it to retrieve route parameters from the component rendered by the matching route
import { UserContext } from "../UserContext.jsx";
import axios from 'axios';

export default function MyFavouritesPage() {

    const apiUrl = axios.defaults.baseURL;
    axios.defaults.withCredentials = true;
    // /bookings/:bookingTitle/:bookingID'
    const { currentUser } = useContext(UserContext);
    const [shadow, setShadow] = useState(-1);
    const [read, setRead] = useState(-1);
    const [places, setPlaces] = useState([]);
    const [favourite, setFavourite] = useState([]);
    const [dataLoaded, setDataLoaded] = useState(false);
   

    useEffect(() => {
        const fetchData = async () => {
            try {
                const { data } = await axios.get('/marketplace');
                setPlaces(data.foundId);
                if (currentUser) {
                    const { data } = await axios.get('/profile/favourite');
                    const favouritePlaceIds = data.map(item => item.placeID);
                    setFavourite(favouritePlaceIds);
                }
                setDataLoaded(true);
            } catch (error) {
                console.error("Error fetching places:", error);
            }
        };

        fetchData();
    }, [currentUser]);

    let shadowClass = "bg-gray-100 p-4 flex gap-4 rounded-2xl  mt-2 mb-4";
    /* These functions are used to handle mouse-over effects on the list of places. mouseOver sets the read and shadow states to display a shadow effect on the hovered place, 
    and mouseOut resets these states when the mouse moves away. */
    function mouseOver(index) {
        setRead(index)
        setShadow(index);
    }

    function mouseOut() {
        setRead(-1);
        setShadow(-1);
    }


    async function handleUnlike(placeID) {
        if (!currentUser) return;
        try {
            axios.post("/profile/unfavourite", { placeID })
            setFavourite(prev => prev.filter(id => id !== placeID));
        } catch (error) {
            console.error("An error occurred while unliking the place: ", error);
        }
    }

    return dataLoaded && (
        <div >
            <div className="text-center pb-4">
                <div to='/marketplace' className="inline-flex gap-1 bg-primary  py-2 px-6 rounded-xl mt-4">
                    <svg width="24px" height="24px" viewBox="0 0 0.96 0.96" version="1.1" xmlns="http://www.w3.org/2000/svg" ><g id="icomoon-ignore" /><path d="M0.657 0.153a0.191 0.191 0 0 1 0.191 0.191 0.19 0.19 0 0 1 -0.059 0.138L0.48 0.793 0.166 0.477a0.19 0.19 0 0 1 -0.053 -0.132 0.191 0.191 0 0 1 0.368 -0.074 0.191 0.191 0 0 1 0.177 -0.118zm0 -0.032A0.223 0.223 0 0 0 0.48 0.208a0.223 0.223 0 0 0 -0.177 -0.087c-0.123 0 -0.223 0.1 -0.223 0.223 0 0.058 0.022 0.113 0.062 0.155L0.48 0.839l0.332 -0.333a0.221 0.221 0 0 0 0.068 -0.161c0 -0.123 -0.1 -0.223 -0.223 -0.223z" fill="#000000" /></svg>
                    <span className="flex">Favourites</span>
                </div>
            </div>
            {/* The code maps over the places array and renders each place in a card-like format. It displays the place title, description, and a "Read more" link. 
                A shadow effect is applied on hover. */}
            {places.length > 0 && places.map((place, index) => (
                <div key={index} className="relative" >
                    {favourite.includes(place._id) && (<>

                        <div className={shadow !== index ? (shadowClass)
                            : (shadowClass + " shadow-lg shadow-gray-500/500")}
                            onMouseOut={mouseOut} onMouseOver={() => mouseOver(index)} onTouchEnd={mouseOut} onTouchStart={() => mouseOver(index)} >
                            <div className=" w-32 h-32 bg-gray-300 grow-0 shrink-0 rounded-2xl">
                                {place.photosFile && place.photosFile.length >= 0 ? (
                                    <img src={`${apiUrl}uploads/${place.photosFile[0]}`} alt="Added photos"
                                        className="rounded-xl object-cover flex h-32 w-full justify-center text-center items-center" />
                                ) : (
                                    <img
                                        src={`${apiUrl}uploads/${"no-img-default.png"}`}
                                        alt="Default"
                                        className="rounded-2xl object-cover aspect-square" />
                                )}
                            </div>
                            <div className=" grow-0 shrink relative">
                                <h2 className=" text-md font-semibold flex sm:hidden "> {place.title.substring(0, 20) + ".."}</h2>
                                <h2 className=" md:text-xl text-md font-semibold hidden sm:flex "> {place.title}</h2>
                                <p className=" text-sm sm:hidden flex mt-1.5">{(place.description.substring(0, 100) + "...")} <br /> </p>
                                <p className=" text-sm hidden sm:flex lg:hidden mt-1.5">{read !== index ? (place.description.substring(0, 200) + "...") : place.description} <br /> </p>
                                <p className=" text-sm hidden lg:flex mt-1.5">{read !== index ? (place.description.substring(0, 300) + "...") : place.description} <br /> </p>
                                <Link to={"/account/places/" + place._id} className="inline-flex items-center underline text-lg my-3 gap-0.5"
                                    onMouseOut={mouseOut} onMouseOver={() => mouseOver(index)} onTouchStart={() => mouseOver(index)} onTouchEnd={mouseOut} >
                                    <span>Read more</span>
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-4 h-4 hidden sm:flex">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 4.5l7.5 7.5-7.5 7.5" />
                                    </svg>
                                </Link>
                            </div>
                        </div>

                        <button className="bg-primary flex gap-1 hover:bg-rose-600 absolute bottom-2 right-2  w-11 h-7 sm:h-auto sm:w-auto z-10 py-1 px-2.5 rounded-xl justify-center items-center"
                            onClick={() => handleUnlike(place._id)}
                            onMouseOut={mouseOut} onMouseOver={() => mouseOver(index)} onTouchStart={() => mouseOver(index)} onTouchEnd={mouseOut}>
                            <svg width="18px" height="18px" viewBox="0 0 0.72 0.72" version="1.1" xmlns="http://www.w3.org/2000/svg"><g id="icomoon-ignore" /><path d="M0.493 0.115a0.143 0.143 0 0 1 0.143 0.143 0.15 0.15 0 0 1 -0.044 0.104L0.36 0.595 0.124 0.358A0.15 0.15 0 0 1 0.085 0.259 0.143 0.143 0 0 1 0.361 0.203 0.143 0.143 0 0 1 0.494 0.115zm0 -0.024A0.165 0.165 0 0 0 0.36 0.156 0.165 0.165 0 0 0 0.227 0.091a0.167 0.167 0 0 0 -0.121 0.283l0.254 0.255 0.249 -0.25A0.165 0.165 0 0 0 0.66 0.259C0.66 0.167 0.585 0.091 0.493 0.091z" fill="#000000" /></svg>
                            <span className="hidden sm:flex">unlike</span>
                        </button>

                    </>) 
                    }
                </div>


            ))}
        </div>


    )
} /* {`${restriction.hidden}`} */