import React from 'react';
import Header from './Header';
import Footer from './Footer';
import CookiesFooter from './CookiesFooter';
import { Outlet } from "react-router-dom";

function Layout() {
    return (
        <>
            <Header />
            <div className=' flex flex-col min-h-screen w-auto 2xl:px-40 xl:px-32 lg:px-24 md:px-14 sm:px-8 px-5 relative'>
                <div className='pb-2 '>
                    <div className="border-b absolute left-0 right-0  w-full  "></div>
                </div>
                <Outlet />
                <Footer />
                <div className='py-3'>
                    <div className="border-b absolute left-0 right-0 w-full"></div>
                </div>
                <CookiesFooter />
            </div>
        </>
    )
}

export default Layout; 