
// createContext lets you create a context that components can provide or read, ot works like props
// useEffect is a React Hook that lets you synchronize a component with an external system.
import React, { useState, createContext, useEffect } from "react";
import axios from 'axios';
//useContext returns the context value for the calling component. It is determined as the value passed 
// to the closest SomeContext.Provider above the calling component in the tree. If there is no such provider, 
// then the returned value will be the defaultValue you have passed to createContext for that context. 
// The returned value is always up-to-date. React automatically re-renders components that read some context if it changes.
export const UserContext = createContext({}); //This line creates a context named UserContext using the createContext function. It initializes the context with an empty object as its default value.

export function UserContextProvider({ children }) { // This defines the UserContextProvider component. It takes a children prop, which represents the child components that will be wrapped by this context provider.

    const [currentUser, setCurrentUser] = useState(null); //Two state variables are initialized using the useState hook. currentUser is used to store user data, and ready indicates whether the data has been loaded
    const [ready, setReady] = useState(false)

    // grab information about the /login information from app.js
    useEffect(() => { //This useEffect is used to fetch user profile data when the component mounts. It checks if there is no currentUser (user data has not been loaded yet) and then makes an HTTP GET request to /profile to retrieve user data. The data is then stored in the currentUser state, and the ready state is set to true.
        // Define an async function to fetch user profile data
        const fetchUserProfile = async () => {
            try {
                if (!currentUser) {
                    // Make an HTTP GET request to fetch user profile data from the server.
                    const {data} = await axios.get('/profile');
                    // console.log(data);
                    setCurrentUser(data);
                    setReady(true);
                }
            } catch (error) {
                // Handle error, log it, or display an error message
                console.error("Error fetching profile:", error);
            }
        };

        // Call the async function
        fetchUserProfile();

    }, [currentUser]);


    // Assuming your server sends an error message if login fails
// console.log(currentUser);


    return (
        //so we can export currentUser and setCurrentUser to be used in Login 
        // Inside the UserContextProvider component, it uses the UserContext.Provider component to wrap its children. It provides the context with values including currentUser, setCurrentUser, and ready. This allows child components to consume these values using the useContext hook.
        <UserContext.Provider value={{ currentUser, setCurrentUser, ready }}>
            {children}
        </UserContext.Provider>
    )
}
/* In summary, this code defines a context provider (UserContextProvider) for user information and uses the useEffect hook to fetch user data when the component mounts. 
The fetched data is stored in the context, and child components can access this data using the useContext hook. This context is useful for managing user authentication and related data throughout the application. */




