import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";   //  Navigate,
import axios from 'axios';
import { UserContext } from "../UserContext.jsx";
import { format, subYears } from "date-fns";
import { LoginPathContext } from "../LoginPathContext";

function RegisterPage() {
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [birthday, setBirthday] = useState('');
    const [username, setEmail] = useState(''); // email
    const [password, setPassword] = useState('');
    const [err, setError] = useState('');
    const [redirect, setRedirect] = useState(false)
    const navigate = useNavigate();
    const { setCurrentUser } = useContext(UserContext);   // setCurrentUser grabed from UserContext with the help of useContext, It works just like prop
    const { contextValue } = useContext(LoginPathContext); // Access the loginPath state from the context
    const [registerButtonLoaderHidden, setRegisterButtonLoaderHidden] = useState(true);
    const birthdayCompliance = () => {
        let result = subYears(new Date(), 18, "dd/MM/yyyy");
        result = format(new Date(result), 'yyyy-MM-dd')
        return result;
    }

    // send request to app.js in api folder
    async function handleSubmit(e) {
        e.preventDefault();
        try {
            setRegisterButtonLoaderHidden(false);
            const userInfo = await axios.post('/register', {
                firstName, lastName, birthday, username, password
            });
            if (userInfo.data.err) {
                setError(userInfo.data.err);
                setRegisterButtonLoaderHidden(true);
                navigate('/register');
            }
            else {
                console.log("Success:", userInfo.data);
                setCurrentUser(userInfo.data);
                setRegisterButtonLoaderHidden(true);
                setRedirect(true);
                setTimeout(() => {
                    alert("Successfully registered" + userInfo.data.username);
                }, 50);

                if (contextValue.length > 1 && contextValue[contextValue.length - 1] !== '/register') {
                    navigate(contextValue[contextValue.length - 1]);
                }
                else if (contextValue.length > 1 && contextValue[contextValue.length - 1] === '/register') {
                    navigate(contextValue[contextValue.length - 2]);
                }
                else if (contextValue[0] === '/register') {
                    navigate('/');
                }
                else {
                    console.log('4th condition caused it ');
                    navigate('/');
                }
            }

        } catch (err) {
            console.error("Error:", err);
            setRegisterButtonLoaderHidden(true);
            setTimeout(() => {
                alert("Registration failed, try again!");
            }, 50);
            navigate('/register');
        }
        if (redirect && err) {
            return
        }
    }
    const scrollToTop = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const preventCopyPaste = (e) => {
        e.preventDefault();
    }

    return (
        <div className="mt-4 md:mt-8 grow flex items-center justify-around " >
            {/* // autoComplete="off" autoComplete="false" */}
            <div className="mb-20 ">
                <h1 className="text-3xl font-medium text-gray-600 text-center mb-4">Register</h1>
                <form className="max-w-md mx-auto " onSubmit={handleSubmit}>
                    <div className="rounded-xl divide-y ">
                        <div>
                            <input type="text" className=" !rounded-none !rounded-ss-md !rounded-se-md h-14 !-my-[0.8px] " placeholder="First name" min="2" required value={firstName}
                                autoComplete="given-name" onChange={(e) => { setFirstName(e.target.value) }} />
                        </div>
                        <div>
                            <input type="text" className=" !rounded-none !rounded-ee-md !rounded-es-md   h-14 !-my-[0.8px] " placeholder="Surname" min="2" required value={lastName}
                                autoComplete="family-name" onChange={(e) => { setLastName(e.target.value) }} />
                        </div>
                    </div>
                    <p className=" font-light text-sm">Make sure it matches the name on your government ID.</p>


                    <div className="rounded-xl mt-1 sm:mt-3 pt-3 h-30 ">
                        <div>  <input type="date" className="!rounded-md font-normal text-gray-500 text border border-gray-400 h-14 w-full" required value={birthday}
                            autoComplete="bday" max={format(new Date(birthdayCompliance()), 'yyyy-MM-dd')} onChange={(e) => { setBirthday(e.target.value) }} /></div>
                    </div>
                    <p className=" font-light text-sm ">You must be at least 18 years old to register. Nookily users will not be able to see your birthday.</p>


                    <div className="rounded-xl  mt-1 sm:mt-3 pt-3 h-30  ">
                        <div>  <input type="email" className="!rounded-md font-normal text border border-gray-400 h-14 w-full " placeholder="your-email@email.com"
                            autoComplete="email" required value={username} onChange={(e) => { setEmail(e.target.value) }}
                            onCut={preventCopyPaste} onCopy={preventCopyPaste} onPaste={preventCopyPaste} onDrag={preventCopyPaste} />
                        </div>
                    </div>
                    <p className=" font-light text-sm ">We will email you booking confirmations.</p>


                    <div className="rounded-xl mt-1 sm:mt-3  pt-3 h-30  ">
                        <div>  <input type="password" className="!rounded-md font-normal text border border-gray-400 h-14 w-full " placeholder="Password" required minLength={8}
                            autoComplete="new-password" pattern="^(?=.*[A-Z])(?=.*[!@#$%^&*])(?=.*[0-9]).{8,}$" value={password} onChange={(e) => { setPassword(e.target.value) }}
                            onCut={preventCopyPaste} onCopy={preventCopyPaste} onPaste={preventCopyPaste} onDrag={preventCopyPaste} /></div>
                    </div>
                    <p className=" font-light text-sm ">A minimum of eight characters is required for the password, and it must contain numerals, uppercase, lowercase, and special characters (@, #, &, etc.). </p>
                    <div className="my-6 flex gap-2">
                        <input className="" type="checkbox" required />
                        <p className=" font-light text-sm ">I <span className="font-semibold">accept</span> the <Link target="_blank" to="/legal/terms-of-use-and-service" className=" underline text-blue-600 ">terms of service</Link> for Nookily, and <Link target="_blank" to="/legal/privacy-policy" className=" underline text-blue-600 ">Nondiscrimination policy</Link>,
                            as well as the <Link target="_blank" to="/legal/privacy-policy" className=" underline text-blue-600 ">Privacy Policy</Link>, by registering.</p>
                    </div>


                    <button className={registerButtonLoaderHidden ? (" primary mt-1 h-12 shadow-md hover:bg-rose-600 !rounded-md ")
                        : ("!bg-rose-400 mt-1 h-12 w-full !rounded-md ")}
                        disabled={!registerButtonLoaderHidden} >
                        {registerButtonLoaderHidden ? (
                            <p className="text-white">Register</p>
                        ) : (
                            <div className="lds-elliipsis" disabled >
                                <div></div>
                                <div></div>
                                <div></div>
                                <div></div>
                            </div>
                        )}
                    </button>
                    {/* <Link to={"/auth/twitter"} className="py-2 mt-2 px-4 flex text-decoration-none h-12  justify-center items-center mb-2 bg-blue-600 hover:bg-blue-700
                     focus:ring-blue-500 focus:ring-offset-blue-200 text-white w-full transition ease-in duration-200 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md ">
                        <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">

                        </svg>
                        Sign in with Twitter
                    </Link>

                    <Link to={"/auth/google"} className="py-2 px-4 flex text-decoration-none justify-center items-center mb-2 bg-red-600 hover:bg-red-700 focus:ring-red-500
                     focus:ring-offset-red-200 text-white w-full transition ease-in duration-200 h-12 text-center text-base font-semibold shadow-md focus:outline-none focus:ring-2 focus:ring-offset-2  rounded-md ">
                        <svg width="20" height="20" fill="currentColor" className="mr-2" viewBox="0 0 1792 1792" xmlns="http://www.w3.org/2000/svg">

                        </svg>
                        Sign in with Google
                    </Link> */}
                    <div className="text-center text-gray-500">
                        <span> Already a member?  </span>
                        <Link className="underline text-black" to={'/login'} onClick={scrollToTop} >Login now </Link>
                    </div>
                </form>
            </div>
        </div>
    )
}
export default RegisterPage;
