import React, { useState } from "react";
import axios from "axios";
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { Link } from "react-router-dom";

function IndexPage() {
    const apiUrl = axios.defaults.baseURL;
    axios.defaults.withCredentials = true;
    const [isHover, setIsHover] = useState(-1);

    const settingsCaptivatingImages = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 8000,
        pauseOnHover: true
    };
    const settingsListings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1280,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2
                }
            },
            {
                breakpoint: 640,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
        ]
    };

    const widgetData = [
        {
            image: "photo_link_1702031019675.jpg",
            title: "Shared place",
            description: "Creating a shared space",
        },
        {
            image: "photo_link_1702031019661.jpeg",
            title: "Entire place",
            description: "Privacy over sharing",
        },
        {
            image: "photo_link_1702111119405.jpg",
            title: "Short stay",
            description: "Nothing is permanent",
        },
        {
            image: "photo_link_1702111427305.jpg",
            title: "Hostel",
            description: "Shared Stories, Adventures",
        },
        {
            image: "photo_link_1702111643390.jpg",
            title: "Hotel",
            description: "Unforgettable Hospitality Bliss",
        }
    ]


    let shadowClass = "bg-gray-100  cursor-pointer rounded-2xl  transition duration-700 items-center ";
    function mouseOver(index) {
        setIsHover(index);
    }
    function mouseOut() {
        setIsHover(-1);
    }

    return (
        
        <div className="grow flex flex-col min-h-screen" >
            <div className="grid grid-cols-1 lg:grid-cols-2 mt-4 gap-3">
                <div className=" gap-2 grid grid-cols-1 content-start text-left pb-2">
                    <h1 className="pb-2 text-2xl text-gray-700 font-semibold ">Discover Your Perfect Living Space</h1>
                    <p className="hidden sm:flex">Nookily is your gateway to cozy, stylish apartments. We are an online platform connecting homeowners, landlords, and property owners with individuals seeking to rent,
                        co-living, sublet, and lease living spaces for short and long-term durations.
                    </p>
                    <p className="pb-1"> Nookily curates a collection of unique accommodations that promise comfort, style, and an unforgettable experience. Your perfect nook is just a click away.
                    </p>
                    <Link to={"/marketplace"} className="bg-primary w-52 p-2 text-center text-white shadow-md shadow-primary-500/500 hover:bg-rose-600">
                        Search in Marketplace
                    </Link>
                </div>
                <div>
                    <Slider {...settingsCaptivatingImages}>
                        <div><img className="w-full max-h-72 aspect-square object-cover" src={`${apiUrl}index_images/photo_link_1702031019667.jpg`} alt="Slide 1" /></div>
                        <div><img className="w-full max-h-72 aspect-square object-cover" src={`${apiUrl}index_images/photo_link_1702031019666.jpeg`} alt="Slide 2" /></div>
                        <div><img className="w-full max-h-72 aspect-square object-cover" src={`${apiUrl}index_images/photo_link_1702111009284.jpg`} alt="Slide 3" /></div>
                        <div><img className="w-full max-h-72 aspect-square object-cover" src={`${apiUrl}index_images/photo_link_1702031064013.jpg`} alt="Slide 4" /></div>
                        <div><img className="w-full max-h-72 aspect-square object-cover" src={`${apiUrl}index_images/photo_link_1702031019665.jpeg`} alt="Slide 5" /></div>
                        <div><img className="w-full max-h-72 aspect-square object-cover" src={`${apiUrl}index_images/photo_link_1702031064013.jpg`} alt="Slide 6" /></div>
                    </Slider>
                </div>

            </div>
            <div className=" my-3 py-3 md:my-4 md:py-4">
                <h1 className="text:xl md:text-2xl font-bold text-gray-700 ">Find your place </h1></div>
            <Slider {...settingsListings} className="-m-1 sm:-m-2 md:-m-4 -my-3 sm:-my-6 md:-my-10 items-center">
                {widgetData.length > 0 && widgetData.map((data, index) => (
                    <Link to={"#"} key={index} className=" p-1 sm:p-2 md:p-4 py-3 sm:py-6 md:py-10 max-w-md ">
                        <div className={isHover !== index ? (shadowClass + " shadow-md shadow-gray-300/40  ") : (shadowClass + " shadow-2xl shadow-gray-300/100 hover:scale-105 overflow-visible relative ")}
                            onMouseOver={() => mouseOver(index)} onMouseOut={mouseOut}
                            onTouchStart={() => mouseOver(index)} onTouchEnd={mouseOut} >
                            <div className="rounded-2xl">
                                <img className="w-full aspect-square object-cover rounded-t-2xl" src={`${apiUrl}index_images/${data.image}`} alt="Find a place" />
                            </div>
                            <div className="m-2 px-3 py-2 gap-2">
                                <h1 className="font-semibold">{data.title}</h1>
                                <h3>{data.description}</h3>
                            </div>
                        </div>
                    </Link>
                ))}
            </Slider>

            <div className="grid grid-cols-1 sm:grid-cols-2 content-center mt-20">
                <div>
                    <h2 className="text-2xl font-bold">  Get the latest news! </h2>
                    <p className="mt-4">  Dear Nookily Community, Exciting updates are on the horizon, and we want you to be the first to know! Stay in the loop with the latest happenings, trends, and insider insights about the world of short and long-term rentals. </p>
                </div>
                <div className=" flex items-center content-">
                    <form className="w-full">
                        <div className="p-2  flex  sm:items-center gap-2 sm:gap-4"  >
                            <input
                                type="email"
                                name="SubscriberEmail"
                                placeholder="john@rhcp.com"
                                style={{ borderRadius: '0' }}
                                className=" border-gray-400  rounded-none sm:text-sm" />

                            <button className="mt-1 w-36  bg-primary px-6 py-2 text-sm font-bold rounded-none shadow-primary-500/500 uppercase tracking-wide text-white transition-none hover:bg-rose-600 sm:mt-0 sm:w-auto sm:shrink-0" >
                                Sign Up
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

export default IndexPage;


