import React from 'react';
import "./App.css"
import { Routes, Route } from 'react-router-dom';
import IndexPage from "./pages/IndexPage.jsx";
import MarketPlace from "./pages/MarketPlace.jsx";
import LoginPage from "./pages/LoginPage.jsx";
import RegisterPage from "./pages/RegisterPage.jsx";
import AccountPage from "./pages/AccountPage.jsx";
import PlaceView from "./pages/PlaceView.jsx";
// import MyFavouritesPage from "./pages/PlaceView.jsx";
import Layout from "./Layout";
import NoPage from "./pages/no-page.jsx";
import axios from 'axios';
import { UserContextProvider } from "./UserContext";
import { LoginPathProvider } from "./LoginPathContext";
import PlacesPage from './pages/PlacesPage';
import PlacesForm from './PlacesForm';
import MarketPlaceSearch from './pages/MarketPlaceSearch.jsx';
import Copyright from './LegalPages/Copyright.jsx';
import Trademarks from './LegalPages/Trademarks.jsx';
import PrivacyPolicy from './LegalPages/PrivacyPolicy.jsx';
import TermsOfUseAndService from './LegalPages/TermsOfUseAndService.jsx';
import FAQs from './helpAndSupportPages/FAQs.jsx';
import SafetyTips from './helpAndSupportPages/SafetyTips.jsx';
// Define your base URL for different environments
const apiUrl = process.env.NODE_ENV === 'production' ? 'https://www.server.nookily.com/' : 'http://localhost:5000/';
//console.log('API URL:', apiUrl);
axios.defaults.baseURL = apiUrl;
axios.defaults.withCredentials = true;

/* Note for  npm start for local deployment (npm start or npm run build:local ) and npm build for production deployment (npm run build:prod) */

function App() {
  return (
    <UserContextProvider>
      {/* <SearchContextProvider> */}
      <LoginPathProvider>
        {/* Route helps map specific url paths to different components that will load on the page
          onAddExcercise = {addExercise}  */}
        {/* wrap application components, including Header, with the LoginPathProvider. This makes the 
        loginPath state available to all components within its scope. */}
        <Routes>
          <Route path="/" element={<Layout />} >
            <Route index element={<IndexPage />} /> {/*  // index specifies this route as the default route for the parent route which is '*/}
            <Route path="/marketplace" element={<MarketPlace />} />
            {/* <Route path="/NookilyAutoChanges2024" element={<IndexPage />} />
            <Route path="/NookilyAutoChanges2024V3" element={<IndexPage />} /> */}
            <Route path="/marketplace/:searchQuery" element={<MarketPlaceSearch />} />
            <Route path="/login" element={<LoginPage />} />
            <Route path="/register" element={<RegisterPage />} />
            <Route path="/account/:subpage" element={<AccountPage />} />
            <Route path="/account/places/:action" element={<PlacesPage />} /> {/*  even if we go a any different route paramater we will stil be in PlacesPage and to be created only once'*/}
            <Route path="account/places/edit/:id" element={<PlacesForm />} /> {/*  even if we go a any different route paramater we will stil be in PlacesForm and to be created only once'*/}
            {/* <Route path="/profile/favourite" element={<MyFavouritesPage />} />  even if we go a any different route paramater we will stil be in PlacesForm and to be created only once'             */}
            <Route path='/place/:pageTitle/:pageID' element={<PlaceView />} /> {/*  even if we go a any different route paramater we will stil be in PlacesForm and to be created only once'*/}
            {/* <Route path='/account/bookings/:bookingID' element={<MyBookingPage />} />  even if we go a any different route paramater we will stil be in PlacesForm and to be created only once'            */}
            <Route path="/legal/copyright" element={<Copyright />} />
            <Route path="/legal/trademarks" element={<Trademarks />} />
            <Route path="/legal/terms-of-use-and-service" element={<TermsOfUseAndService />} />
            <Route path="/legal/privacy-policy" element={<PrivacyPolicy />} />
            <Route path="/help-and-support/FAQs" element={<FAQs />} />
            <Route path="/help-and-support/safety-tips" element={<SafetyTips />} />
            <Route path="*" element={<NoPage />} />
            {/*  //This will act as a catch all for undefined URLs. This is great for a 404 error page */}
          </Route>
        </Routes>
      </LoginPathProvider>
      {/* </SearchContextProvider> */}
    </UserContextProvider>

  );
}

export default App;

