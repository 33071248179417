import axios from "axios";

export default function ShowAllPhotos(props) {
    const apiUrl = axios.defaults.baseURL;
    axios.defaults.withCredentials = true;
    const allPhotos = props.place.photosFile;
    
    
    return (
        <div className="absolute inset-0 bg-white min-h-screen z-50 " >
            <div className="flex justify-between fixed  w-full  md:px-6 top-0 h-24 items-center bg-white ">
                <button className="bg-transparent" onClick={() => {props.setShowPhotos(false); window.scrollTo({ top: 0, behavior: "instant" })} }>
                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                        <path strokeLinecap="round" strokeLinejoin="round" d="M15.75 19.5L8.25 12l7.5-7.5" />
                    </svg>
                </button>
                
                <div className="flex ">
                    <button className="bg-transparent flex sm:gap-6">
                        <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" className="w-6 h-6">
                            <path strokeLinecap="round" strokeLinejoin="round" d="M7.217 10.907a2.25 2.25 0 100 2.186m0-2.186c.18.324.283.696.283 1.093s-.103.77-.283 1.093m0-2.186l9.566-5.314m-9.566 7.5l9.566 5.314m0 0a2.25 2.25 0 103.935 2.186 2.25 2.25 0 00-3.935-2.186zm0-12.814a2.25 2.25 0 103.933-2.185 2.25 2.25 0 00-3.933 2.185z" />
                        </svg>
                        <span className="underline sm:flex hidden">Share</span>
                    </button>
                </div>

            </div>
            <div className=" grid gap-1.5 justify-items-center lg:px-40  md:px-20 sm:px-12 px-4">   
                {allPhotos.length > 0 && allPhotos.map((photo,index) => (
                    <div key={index}>
                        <img src={`${apiUrl}uploads/${photo}`}  alt="allphotos"></img>
                    </div>
                ))}
            </div>


        </div>
    )
}