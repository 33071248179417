import { Link } from "react-router-dom";

export default function SuccessfulPlacePage() {
    return (
        <div className="grow flex items-center justify-around flex-col absolute bg-gray-100 w-full inset-0 z-40 min-h-screen " >
            <div className="m-2">
                <div className="relative bottom-80 md:bottom-20 p-8 sm:p-20 rounded-xl shadow-md shadow-gray-500/50 bg-white grid grid-cols-1 items-center">
                    <svg width="100px" className="place-self-center" height="100px" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg"><path width="48" height="48" fill="white" fillOpacity="0.01" d="M0 0H6V6H0V0z" /><path d="m3 0.5 0.657 0.479 0.813 -0.002 0.25 0.773 0.658 0.476L5.125 3l0.253 0.772 -0.658 0.476 -0.25 0.773 -0.813 -0.002L3 5.5l-0.657 -0.479 -0.813 0.002 -0.25 -0.773 -0.658 -0.476L0.875 3l-0.253 -0.772 0.658 -0.476 0.25 -0.773 0.813 0.002L3 0.5Z" fill="#f5385d" stroke="white" strokeLinecap="round" strokeLinejoin="round" strokeWidth="0.125" /><path d="m2.125 3 0.625 0.625 1.25 -1.25" stroke="white" strokeWidth="0.5" strokeLinecap="round" strokeLinejoin="round" /></svg>
                    <h1 className="text-3xl font-medium text-gray-600 text-center my-6">Sucessful</h1>
                    <p className="text-gray-600 text-center pb-4">Completed! You should receive an email shortly.</p>
                    <Link to={"/"} className="w-auto p-2.5 place-self-center rounded-md hover:bg-rose-600 bg-primary text-white">Go home</Link>
                </div>
            </div>
        </div>
    )
}